<template>
  <div class="container">
    <LiveComponent
      ref="liveComponent"
      :channelNoList="channelNoList"
      :playerWidth="isMobile ? 350 : 850"
      :playerHeight="isMobile ? 200 : 400"
      :accessToken="accessToken"
      :firstPage="false"
      :multiPage="true"
      nextPagePath="/yingshiLive"
    />
  </div>
</template>

<script>
import LiveComponent from '@/views/live/_components/LiveComponent'

export default {
  data() {
    return {
      channelNoList: [5, 6, 7, 8]
    }
  },
  components: { LiveComponent },
  computed: {
    accessToken() {
      return this.$store.getters.accessToken
    },
    isMobile() {
      return this.$device?.mobile
    }
  },
  async mounted() {
    if (!this.accessToken) {
      // 萤石云 appKey
      const appKey = '1b5fd63fc270458ba4511f4e4983e326'
      const appSecret = 'd5b474eb833197a01dae2f6e3aa3ac08'
      this.$store.commit('handlerYingshiyunToken/SET_KEYINFO', {
        appKey,
        appSecret
      })
      this.$store.dispatch('handlerYingshiyunToken/getAccessToken', {
        appKey,
        appSecret
      })
    }
    if (this.isMobile) return
    this.$refs.liveComponent.initPlayer()
  }
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  padding: 32px 16px 0;
}
/deep/.el-tabs__item {
  color: #fff;
  &.is-active {
    color: #409eff;
  }
}
</style>
