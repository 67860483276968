import Amap3D from '@/views/amap/3DAmapDark.vue'
import Amap3DLight from '@/views/amap/3DAmapLight.vue'
import Amap3DOld from '@/views/amap/3DAmapold.vue'
import YingshiLive from '@/views/live/YingshiLive'
import YingshiLive2 from '@/views/live/YingshiLive2'
import SigaisanLive from '@/views/live/SigaisanLive.vue'
import DongyangLive from '@/views/live/DongyangLive.vue'
import Jiaxing320Live from '@/views/live/Jiaxing320Live.vue'
import FullScreenPlayer from '@/views/live/_components/FullScreenPlayer.vue'
import ExtraPlayer from '@/views/live/_components/ExtraPlayer.vue'
import MobilePlayer from '@/views/live/_components/MobilePlayer'
import WenzhoubeiLive from '@/views/live/WenzhoubeiLive'
import Amap from '@/views/amap/Amap'
import VueRouter from 'vue-router'
import ThreeMap from '@/views/amap/ThreeMap'
import EasyvMap from '@/views/amap/EasyvMap.vue'
import GanttChart from '@/views/gantt/Index.vue'
import TestPage from '@/views/TestPage.vue'
import SuperMap from '@/views/supermap/Index.vue'

export const routes = [
  {
    path: '/',
    redirect: '/ganttChart',
    meta: { title: '首页/甘特图' }
  },
  {
    path: '/portal',
    name: 'Portal',
    component: () => import('@/views/Index.vue'),
    meta: { title: '门户' }
  },
  {
    path: '/test',
    name: 'TestPage',
    component: TestPage,
    meta: { title: '测试页' }
  },
  {
    path: '/ganttChart',
    name: 'GanttChart',
    component: GanttChart,
    meta: { title: '甘特图' }
  },
  {
    path: '/yingshiLive',
    name: 'YingshiLive',
    component: YingshiLive,
    meta: { title: '萤石云监控' }
  },
  {
    path: '/yingshiLive2',
    name: 'YingshiLive2',
    component: YingshiLive2,
    meta: { title: '萤石云监控2' }
  },
  {
    path: '/fullScreenPlayer',
    name: 'FullScreenPlayer',
    component: FullScreenPlayer,
    meta: { title: '全屏pc监控' }
  },
  {
    path: '/extraPlayer',
    name: 'ExtraPlayer',
    component: ExtraPlayer,
    meta: { title: '额外pc监控' }
  },
  {
    path: '/mobilePlayer',
    name: 'MobilePlayer',
    component: MobilePlayer,
    meta: { title: '手机端播放监控' }
  },
  {
    path: '/wenzhoubeiLive',
    name: 'WenzhoubeiLive',
    component: WenzhoubeiLive,
    meta: { title: '温州北监控' }
  },
  {
    path: '/sigaisanLive',
    name: 'SigaisanLive',
    component: SigaisanLive,
    meta: { title: '四改三监控' }
  },
  {
    path: '/dongyangLive',
    name: 'DongyangLive',
    component: DongyangLive,
    meta: { title: '东阳监控' }
  },
  {
    path: '/jiaxing320Live',
    name: 'Jiaxing320Live',
    component: Jiaxing320Live,
    meta: { title: '嘉兴320监控' }
  },
  {
    path: '/amap',
    name: 'Amap',
    component: Amap,
    meta: { title: '高德地图' }
  },
  {
    path: '/superMap',
    name: 'SuperMap',
    component: SuperMap,
    meta: { title: '超图' }
  },
  {
    path: '/amap3D',
    name: 'Amap3D',
    component: Amap3D,
    meta: { title: '3D高德' }
  },
  {
    path: '/amap3DOld',
    name: 'Amap3DOld',
    component: Amap3DOld,
    meta: { title: '老版3D高德' }
  },
  {
    path: '/threeMap',
    name: 'ThreeMap',
    component: ThreeMap,
    meta: { title: 'three3D高德' }
  },
  {
    path: '/amap3DLight',
    name: 'Amap3DLight',
    component: Amap3DLight,
    meta: { title: '3D高德亮版' }
  },
  {
    path: '/easyvMap',
    name: 'EasyvMap',
    component: EasyvMap,
    meta: { title: '驾驶舱地图' }
  }
]

export default new VueRouter({
  routes,
  mode: 'history'
})
