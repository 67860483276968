<template>
  <div class="container">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(camera, index) in cameraInfoList"
        :key="index"
        :label="camera.name"
        :name="camera.deviceSerial"
      >
        <LiveComponent
          type="siGaiSan"
          :accessToken="accessToken"
          :ref="`liveComponent_${camera.deviceSerial}`"
          :channelNoList="camera.channelNoList"
          :deviceSerial="camera.deviceSerial"
          :playerWidth="isMobile ? 350 : 850"
          :playerHeight="isMobile ? 200 : 400"
        />
      </el-tab-pane>
    </el-tabs> -->
    <LiveComponent
      type="siGaiSan"
      :accessToken="accessToken"
      :cameraInfoList="cameraInfoList"
      :extraData="extraData"
      :isOnePageMutil="true"
      :playerWidth="isMobile ? 350 : 850"
      :playerHeight="isMobile ? 200 : 400"
    />
  </div>
</template>

<script>
import LiveComponent from '@/views/live/_components/LiveComponent'

const extraData = [
  {
    name: '松旺村临时码头1',
    url: 'https://open.ys7.com/v3/openlive/673343432_1_2.m3u8?expire=1733921291&id=656598541740802048&t=5cfe2b16342e36bd49edde1fed1fd500896951ed30f89c0cb715904411195f01&ev=100'
  },
  {
    name: '松旺村临时码头2',
    url: 'https://open.ys7.com/v3/openlive/678175585_1_1.m3u8?expire=1733921247&id=656598356490981376&t=3f176ac696b05fe13a53982e98f35142a51d6b9aa735ee0aa813cc99bbbc5786&ev=100'
  }
]

export default {
  data() {
    return {
      // channelNoList: [1, 2, 3],
      // deviceSerialList: ['L21081319', 'AB8802699'],
      activeName: 'AY1707982',
      extraData,
      cameraInfoList: [
        // {
        //   deviceSerial: 'AY1707982',
        //   name: '杨家大桥南',
        //   channelNoList: [1]
        // },
        // {
        //   deviceSerial: 'AY1708047',
        //   name: '杨家大桥北',
        //   channelNoList: [1]
        // },
        {
          deviceSerial: 'AY1707955',
          name: '龙游服务区',
          channelNoList: [1]
        },
        {
          deviceSerial: 'AY1707946',
          name: '龙游湖镇新屋服务区',
          channelNoList: [1]
        },
        {
          deviceSerial: 'AY1708030',
          name: '船厂',
          channelNoList: [1]
        },
        // {
        //   deviceSerial: 'AY1707944',
        //   name: '信安湖服务区1',
        //   channelNoList: [1]
        // },
        // {
        //   deviceSerial: 'FA8690416',
        //   name: '信安湖服务区2',
        //   channelNoList: [1]
        // },
        // {
        //   deviceSerial: 'FA8690415',
        //   name: '信安湖服务区3',
        //   channelNoList: [1]
        // },
        // {
        //   deviceSerial: 'FA8690498',
        //   name: '信安湖服务区4',
        //   channelNoList: [1]
        // },
        {
          deviceSerial: 'FC6939511',
          name: '杨家大桥南岸门卫',
          channelNoList: [1]
        },
        {
          deviceSerial: 'FC6939511',
          name: '杨家大桥钢筋棚南',
          channelNoList: [2]
        },
        {
          deviceSerial: 'FC6939511',
          name: '杨家大桥钢筋棚北',
          channelNoList: [3]
        },
        {
          deviceSerial: 'FC6939511',
          name: '杨家大桥门卫北',
          channelNoList: [4]
        },
        {
          deviceSerial: 'FC6939511',
          name: '杨家大桥南岸',
          channelNoList: [5]
        },
        {
          deviceSerial: 'FC6939511',
          name: '杨家大桥北岸',
          channelNoList: [6]
        },
        {
          deviceSerial: 'FC6939511',
          name: '杨家大桥桥面',
          channelNoList: [7]
        },
        {
          deviceSerial: 'FC6939511',
          name: '信安湖钢筋棚东',
          channelNoList: [8]
        },
        {
          deviceSerial: 'FC6939511',
          name: '信安湖钢筋棚西',
          channelNoList: [9]
        },
        {
          deviceSerial: 'FC6939511',
          name: '信安湖门卫',
          channelNoList: [10]
        },
        {
          deviceSerial: 'FC6939511',
          name: '信安湖服务区1',
          channelNoList: [11]
        },
        {
          deviceSerial: 'FC6939511',
          name: '信安湖服务区2',
          channelNoList: [12]
        },
        {
          deviceSerial: 'FC6939511',
          name: '信安湖服务区3',
          channelNoList: [13]
        },
        {
          deviceSerial: 'FC6939511',
          name: '信安湖力学室',
          channelNoList: [14]
        },
        {
          deviceSerial: 'FC6939511',
          name: '信安湖服务区4',
          channelNoList: [15]
        }
      ]
    }
  },
  components: { LiveComponent },
  computed: {
    accessToken() {
      return this.$store.getters.accessToken
    },
    isMobile() {
      return this.$device?.mobile
    }
  },
  methods: {
    handleClick(tab, e) {
      console.log(tab, e)
    }
  },
  async mounted() {
    // 四改三 key
    const appKey = '85214e7240b74476a1a54476f02a61b5'
    const appSecret = '9db1ea7c2a162951c4924f59e03ee2b9'
    this.$store.commit('handlerYingshiyunToken/SET_KEYINFO', {
      appKey,
      appSecret
    })
    this.$store.dispatch('handlerYingshiyunToken/getAccessToken', {
      appKey,
      appSecret
    })
  }
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  padding: 16px;
}
/deep/.el-tabs__item {
  color: #fff;
  &.is-active {
    color: #409eff;
  }
}
</style>
