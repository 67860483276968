<template>
  <div :class="isEzv ? `container ${colorType}` : 'container'">
    <header class="top-bar">
      <el-form :inline="true" size="small">
        <el-form-item v-if="!noTime" label="时间范围：">
          <el-date-picker
            v-model="times"
            size="small"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 400px"
          ></el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="行数">
          <el-input
            v-model.number="rowNum"
            size="small"
            style="width: 60px"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="块数">
          <el-input
            v-model.number="colNum"
            size="small"
            style="width: 60px"
            placeholder=""
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="行高：">
          <el-slider
            v-model="cellHeight"
            :min="20"
            :max="100"
            style="width: 60px"
            size="small"
          ></el-slider> 
        </el-form-item> -->
        <el-form-item label="列宽：">
          <el-slider
            v-model="cellWidth"
            :min="20"
            :max="100"
            style="width: 100px"
            size="small"
          ></el-slider>
        </el-form-item>
        <el-form-item label="单位时间：">
          <el-select
            v-model="scale"
            placeholder=""
            style="width: 100px"
            size="small"
          >
            <el-option
              v-for="item in scaleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-checkbox v-model="hideHeader">hideHeader</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="hideSecondGantt">hideSecondGantt</el-checkbox>
        </el-form-item> -->
      </el-form>
      <div class="type-group" v-if="!propsOnlyPlan">
        <div class="block">
          <div class="icon-group plan"></div>
          计划
        </div>
        <div class="block">
          <div class="icon-group real"></div>
          实际
        </div>
      </div>
    </header>
    <v-gantt-chart
      v-if="datas && datas.length"
      class="gantt-group"
      :startTime="times[0]"
      :endTime="times[1]"
      :datas="datas"
      :cellHeight="cellHeight"
      :titleWidth="760"
      :titleHeight="80"
      :cellWidth="cellWidth"
      :scale="scale"
      :timelines="timelines"
    >
      <template v-slot:block="{ data, item }">
        <!-- 你的容器块组件 -->
        <GanttBlock :data="data" :item="item"></GanttBlock>
      </template>
      <template v-slot:left="{ data }">
        <!-- 你的行名组件 -->
        <GanttTableRow class="row-group" :data="data"></GanttTableRow>
      </template>
      <template v-slot:title>
        <!-- 你的表头组件 -->
        <GanttColumn></GanttColumn>
      </template>
    </v-gantt-chart>
    <!-- <el-empty v-else class="gantt-group" description="暂无数据"></el-empty> -->
    <div v-else class="empty">暂无数据</div>
  </div>
</template>

<script>
import vGanttChart from 'v-gantt-chart'
import dayjs from 'dayjs' //时间库
import GanttColumn from './_components/GanttColumn.vue'
import GanttTableRow from './_components/GanttTableRow.vue'
import GanttBlock from './_components/GanttBlock.vue'

// const scaleList =
//   `1,2,3,4,5,6,10,12,15,20,30,60,120,180,240,360,720,1440,2880,4320,10080`
//     .split(',')
//     .map((n) => {
//       let value = parseInt(n)
//       let label
//       if (value < 60) {
//         label = value + '分钟'
//       } else if (value >= 60 && value < 1440) {
//         label = value / 60 + '小时'
//       } else {
//         label = value / 1440 + '天'
//       }
//       return {
//         value,
//         label
//       }
//     })

const scaleList = `1440,10080,20160,30240,40320`.split(',').map((n) => {
  let value = parseInt(n)
  let label
  if (value >= 1440 && value < 10080) {
    label = value / 1440 + '天'
  } else {
    label = value / 10080 + '周'
  }
  return {
    value,
    label
  }
})

export default {
  data() {
    return {
      times: [
        // dayjs().subtract(1, 'month').toString(),
        // dayjs().add(1, 'month').subtract(1, 'day').toString()
      ],
      datas: [],
      cellWidth: 70,
      cellHeight: 55,
      scale: 1440,
      scaleList: scaleList,
      // startTime: '2023-09-14 00:00:00', //时间轴开始时间
      // endTime: '2023-10-20 00:00:00', //时间结束时间
      timelines: [
        { time: '2023-09-14 20:18:18', color: '#747e80' },
        {
          time: dayjs().add(5, 'hour').toString(),
          text: 'try',
          color: '#747e80'
        }
      ]
    }
  },
  // props: {
  //   times: {
  //     type: Array,
  //     default: () => [
  //       dayjs().subtract(1, 'month').toString(),
  //       dayjs().add(1, 'month').subtract(1, 'day').toString()
  //     ]
  //   }
  // },
  components: { vGanttChart, GanttColumn, GanttTableRow, GanttBlock },
  mounted() {
    window.$wujie?.bus.$on('updateData', (data, name) => {
      if (name !== this.propName) return
      this.datas = [...data]
    })
    window.$wujie?.bus.$on('onReady', (name) => {
      if (name !== this.propName) return
      this.noticeStart()
    })
    this.noticeStart()
  },
  computed: {
    propTimes() {
      return window.$wujie?.props.times
    },
    propName() {
      return window.$wujie?.props.partName
    },
    noTime() {
      return window.$wujie?.props.noTime
    },
    propsOnlyPlan() {
      return window.$wujie?.props.onlyPlan
    },
    isEzv() {
      return window.$wujie?.props.isEzv
    },
    colorType() {
      return window.$wujie?.props.colorType
    }
  },
  watch: {
    propTimes: {
      handler(newV) {
        if (newV) {
          this.times = [...newV]
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    noticeStart() {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        window.$wujie?.bus.$emit('subAppStart', true, this.propName)
      })
    }
    // onExpend() {
    //   this.datas.push({
    //     id: 'test1', //非必须
    //     gtArray: [
    //       //默认的需要渲染的数组
    //       {
    //         name: '1', //非必须
    //         start: '2023-09-14 18:18:18',
    //         end: '2023-9-15 18:18:18'
    //       },
    //       {
    //         name: '2', //非必须
    //         start: '2023-09-15 18:18:18',
    //         end: '2023-09-16 18:18:18'
    //       }
    //     ],
    //     customKey: [
    //       //自定义的需要渲染的数组
    //       {
    //         id: 'test', //非必须
    //         start: '2023-09-16 18:18:18',
    //         end: '2023-9-17 18:18:18'
    //       }
    //     ]
    //   })
    // }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  margin-bottom: 40px;
  .gantt-group {
    width: 100%;
    height: 700px;
  }
  .loading-group {
    width: 100%;
    height: 700px;
  }
  /deep/ .gantt-timeline-block {
    position: relative;
    left: 35px;
  }
  /deep/ .gantt-leftbar {
    // &:nth-child(2n) {
    //   background-color: #eff8ff !important;
    // }
    .gantt-leftbar-item {
      .row-group {
        // border-bottom: 1px solid #e2e4e8 !important;
        &:hover {
          background: #dee4f6;
        }
      }
    }
    .gantt-leftbar-item:nth-child(2n) {
      background-color: #eff8ff !important;
    }
  }
  .empty {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    // border: 1px solid #e2e4e8;
    color: #5e6d82;
  }
  .type-group {
    width: 100%;
    display: flex;
    height: 40px;
    line-height: 40px;
    justify-content: flex-end;
    .block {
      height: 100%;
      margin-right: 20px;
      display: flex;
      align-items: center;
      .icon-group {
        width: 20px;
        height: 10px;
        margin-right: 5px;
        border-radius: 5%;
      }
      .real {
        background: #4caca7;
      }
      .plan {
        background: #4a8de0;
      }
    }
  }
}
.Ezv {
  @color: #24668b;
  background: @color;
  /deep/ .el-input__inner {
    background: transparent;
    color: #ffffff;
    .el-range__icon {
      color: #ffffff;
    }
    .el-range-input {
      background: transparent;
      color: #ffffff;
    }
    .el-range-separator {
      color: #ffffff;
    }
  }
  /deep/ .el-form-item {
    .el-form-item__label {
      color: #ffffff;
    }
    .el-select .el-input {
      .el-input__suffix {
        color: #ffffff;
        .el-select__caret {
          color: #ffffff;
        }
      }
    }
  }
  /deep/ .gantt-chart {
    outline-color: #ffffff;
    .gantt-container {
      .gantt-header {
        background: @color;
        .gantt-header-title {
          background: transparent;
          ul {
            background: transparent;
            li {
              color: #ffffff;
              border-color: #ffffff;
            }
          }
        }
        .gantt-timeline-day {
          color: #ffffff;
        }
      }
      .gantt-leftbar-wrapper {
        background: @color;
        outline-color: #ffffff;
        .gantt-leftbar {
          background: transparent;
          .gantt-leftbar-item {
            background: transparent !important;
            ul {
              li {
                color: #ffffff;
              }
            }
            .row-group {
              // border-bottom: 1px solid #e2e4e8 !important;
              &:hover {
                background: rgba(255, 141, 67, 0.9);
              }
            }
          }
        }
      }
    }
  }
}
.Ezv1 {
  @color: #065f6b;
  background: @color;
  /deep/ .el-input__inner {
    background: transparent;
    color: #ffffff;
    .el-range__icon {
      color: #ffffff;
    }
    .el-range-input {
      background: transparent;
      color: #ffffff;
    }
    .el-range-separator {
      color: #ffffff;
    }
  }
  /deep/ .el-form-item {
    .el-form-item__label {
      color: #ffffff;
    }
    .el-select .el-input {
      .el-input__suffix {
        color: #ffffff;
        .el-select__caret {
          color: #ffffff;
        }
      }
    }
  }
  /deep/ .gantt-chart {
    outline-color: #ffffff;
    .gantt-container {
      .gantt-header {
        background: @color;
        .gantt-header-title {
          background: transparent;
          ul {
            background: transparent;
            li {
              color: #ffffff;
              border-color: #ffffff;
            }
          }
        }
        .gantt-timeline-day {
          color: #ffffff;
        }
      }
      .gantt-leftbar-wrapper {
        background: @color;
        outline-color: #ffffff;
        .gantt-leftbar {
          background: transparent;
          .gantt-leftbar-item {
            background: transparent !important;
            ul {
              li {
                color: #ffffff;
              }
            }
            .row-group {
              // border-bottom: 1px solid #e2e4e8 !important;
              &:hover {
                background: rgba(255, 141, 67, 0.9);
              }
            }
          }
        }
      }
    }
  }
}
</style>
