import request from '@/utils/request'

export const api = {
  getAccessToken: '/lapp/token/get',
  getChannelList: '/rest/video/equipment/listByPage',
  getOpenUrl: '/lapp/v2/live/address/get',
  getThumbnailUrl: '/lapp/device/capture',
  turnoffSercet: '/lapp/device/encrypt/off',
  ptzStart: '/lapp/device/ptz/start',
  ptzStop: '/lapp/device/ptz/stop'
}

export function getAccessToken(data) {
  return request({
    url: api.getAccessToken,
    method: 'post',
    data
  })
}

export function getOpenUrl(data) {
  return request({
    url: api.getOpenUrl,
    method: 'post',
    data
  })
}

export function getThumbnailUrl(data) {
  return request({
    url: api.getThumbnailUrl,
    method: 'post',
    data
  })
}

export function ptzStart(data) {
  return request({
    url: api.ptzStart,
    method: 'post',
    data
  })
}

export function ptzStop(data) {
  return request({
    url: api.ptzStop,
    method: 'post',
    data
  })
}

export function turnoffSercet(data) {
  return request({
    url: api.turnoffSercet,
    method: 'post',
    data
  })
}
