<template>
  <Amap3D
    styleType="light"
    mapkey="a8c04fc107e42c7a38880c0746d44974"
    securityJsCode="af7e886392a450acea6cb9efc5bd6e63"
    mapStyle="amap://styles/a999971952c9ae9b266735f8224f937b"
  ></Amap3D>
</template>

<script>
import Amap3D from '@/views/amap/3DAmap'

export default {
  components: { Amap3D }
}
</script>
