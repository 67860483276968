import { render, staticRenderFns } from "./3DAmapold.vue?vue&type=template&id=31ee0abf&scoped=true&"
import script from "./3DAmapold.vue?vue&type=script&lang=js&"
export * from "./3DAmapold.vue?vue&type=script&lang=js&"
import style0 from "./3DAmapold.vue?vue&type=style&index=0&id=31ee0abf&prod&lang=less&scoped=true&"
import style1 from "./3DAmapold.vue?vue&type=style&index=1&id=31ee0abf&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ee0abf",
  null
  
)

export default component.exports