<template>
  <div class="container">
    <div class="video-container" id="video-container"></div>
    <div v-if="isShowBackBtn" class="changePage" @click="$router.go(-1)">
      < 返回
    </div>
    <div
      class="btn-container"
      v-if="
        !isPlayBack &&
        (['wenZhouBei', 'dongYang', 'siGaiSan'].includes(type) ||
          (type === 'daRuoYan' && channelNo == 1))
      "
    >
      <div class="btn-group">
        <template v-for="direction in [0, 1, 2, 3, 8, 9]">
          <img
            :key="direction"
            :class="`d d${direction}`"
            :src="getDirectionImg(direction)"
            @click.stop="onPtzStart(channelNo, direction)"
            alt=""
          />
        </template>
      </div>
    </div>
    <div
      v-if="['siGaiSan'].includes(type)"
      class="play-back-btn"
      @click="onClickPlayBackBtn"
    >
      <img
        class="play-back-img"
        src="../../../assets/live/playBack.png"
        alt=""
      />
      <div>{{ !isPlayBack ? '回放' : '返回' }}</div>
    </div>
    <div v-if="isTimePickerShow" class="play-back-time-picker">
      请选择回放时间：
      <el-date-picker
        v-model="timeRange"
        type="datetimerange"
        range-separator="至"
        value-format="yyyyMMddHHmmss"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
      <el-button
        class="play-back-time-picker-btn"
        type="primary"
        @click="onPlayBack"
      >
        播放
      </el-button>
      <el-button
        class="play-back-time-picker-btn"
        @click="isTimePickerShow = false"
      >
        取消
      </el-button>
    </div>
  </div>
</template>

<script>
import { ptzStart, ptzStop } from '@/api/yingshiLive'
import EZUIKit from 'ezuikit-js'
import { debounce } from '@/utils'

export default {
  name: 'FullScreenPlayer',
  data() {
    return {
      liveUrl: '',
      isPlayBack: false,
      timeRange: [],
      isTimePickerShow: false,
      timer: null,
      player: null,
      accessToken: '',
      deviceSerial: '',
      channelNo: null,
      type: '',
      isShowBackBtn: true
    }
  },
  methods: {
    onClickPlayBackBtn() {
      if (this.isPlayBack) {
        const { accessToken, deviceSerial, liveUrl } = this
        this.player.changePlayUrl({
          url: liveUrl,
          accessToken,
          deviceSerial
        })
        this.isPlayBack = false
      } else {
        this.isTimePickerShow = true
      }
    },
    async onPlayBack() {
      // 将时间范围转换为时间戳
      const startTime = this.timeRange[0]
      const endTime = this.timeRange[1]
      // 获取回放地址
      const { accessToken, deviceSerial, channelNo } = this
      const playBackUrl =
        `ezopen://open.ys7.com/${deviceSerial}/${channelNo}.rec` +
        (startTime ? `?begin=${startTime}&end=${endTime}` : '')
      this.player.changePlayUrl({
        url: playBackUrl,
        accessToken,
        deviceSerial
      })
      this.isTimePickerShow = false
      this.isPlayBack = true
    },
    getDirectionImg(direction) {
      return require(`../../../assets/live/d${direction}.png`)
    },
    onPtzStart: debounce(
      async function (channelNo, direction, speed = 1) {
        try {
          const { accessToken, deviceSerial } = this
          await ptzStart({
            accessToken,
            deviceSerial,
            channelNo,
            direction,
            speed
          })
          const timer = setTimeout(() => {
            clearTimeout(timer)
            this.onPtzStop(channelNo, accessToken, deviceSerial)
          }, 500)
        } catch (e) {
          throw new Error(e)
        }
      },
      200,
      false
    ),
    async onPtzStop(channelNo, accessToken, deviceSerial) {
      try {
        await ptzStop({
          accessToken,
          deviceSerial,
          channelNo
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    startTimeout() {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.player.stop()
        this.$alert('您已长时间观看监控，请重新获取视频源', '提示', {
          confirmButtonText: '确定'
        }).then(() => {
          window.location.reload()
        })
      }, 600000)
    }
  },
  async mounted() {
    const { accessToken, deviceSerial, channelNo, type, url, isShowBackBtn } =
      this.$route.query
    this.liveUrl = url
    this.isShowBackBtn = Boolean(isShowBackBtn !== '1')
    this.accessToken = accessToken
    this.deviceSerial = deviceSerial
    this.channelNo = channelNo
    this.type = type
    this.player = new EZUIKit.EZUIKitPlayer({
      id: 'video-container', // 视频容器ID
      accessToken,
      url
    })
    this.startTimeout()
    document.addEventListener('mousemove', this.startTimeout)
    document.addEventListener('keydown', this.startTimeout)
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    document.removeEventListener('mousemove')
    document.removeEventListener('keydown')
    this.player.stop()
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  position: relative;

  .video-container {
    width: 100%;
    height: 100vh;
  }

  .changePage {
    width: 160px;
    height: 160px;
    color: #fff;
    position: absolute;
    font-size: 24px;
    top: 20px;
    left: 20px;
    z-index: 999;
    cursor: pointer;
  }

  .play-back-time-picker {
    width: 100%;
    height: 80px;
    line-height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: #fff;
    padding: 0px 20px;
    box-sizing: border-box;
    .play-back-time-picker-btn {
      margin-left: 20px;
    }
  }

  .play-back-btn {
    width: 90px;
    height: 24px;
    background-image: linear-gradient(180deg, #3fccff 0%, #1e9dff 100%);
    border-radius: 13px;
    font-size: 13px;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    z-index: 999;
    bottom: 40px;
    right: 200px;
    .play-back-img {
      width: 13px;
      height: 13px;
      margin-right: 5px;
    }
  }

  .btn-group {
    z-index: 999;

    .d {
      width: 48px;
      height: 48px;
      position: absolute;
      z-index: 999;
      cursor: pointer;
    }

    .d0 {
      right: 80px;
      bottom: 180px;
    }

    .d1 {
      right: 80px;
      bottom: 100px;
    }

    .d2 {
      right: 125px;
      bottom: 140px;
    }

    .d3 {
      right: 36px;
      bottom: 140px;
    }

    .d8 {
      right: 125px;
      bottom: 40px;
    }

    .d9 {
      right: 36px;
      bottom: 40px;
    }
  }
}
</style>
