<template>
  <div>
    <div
      id="container"
      ref="container"
      @click="onClick"
      @mousemove.prevent="onMousemove"
    >
      <el-button class="button-group" @click.stop="onFullScreen">
        全屏
      </el-button>
    </div>
    <el-dialog
      title="坐标集合"
      :visible.sync="dialogVisible"
      width="100%"
      fullscreen
    >
      <div class="arr-group">
        {{ arr }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onCancel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { debounce } from '@/utils'
import { roadPath } from '../amap/_data/daRuoYanCons'

export default {
  name: 'SuperMap',
  data() {
    return {
      arr: [],
      checkFull: false,
      amap: null,
      polyline: {},
      dialogVisible: false,
      fullscreenFlag: false
    }
  },
  computed: {
    roadPath() {
      return roadPath.map(({ lng, lat }) => [lng, lat])
    }
  },
  methods: {
    onCancel() {
      this.arr = []
      this.dialogVisible = false
    },
    onFullScreen() {
      const div = this.$refs.container
      this.launchIntoFullscreen(div)
    },
    checkFull1() {
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) {
        isFull = false
      }
      return isFull
    },
    //全屏封装
    launchIntoFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    },
    //退出全屏封装
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    },

    onClick() {
      this.checkFull = !this.checkFull
      if (!this.checkFull) {
        // const marker = new this.amap.Marker({
        //   icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        //   position: this.arr[this.arr.length - 1]
        //   // offset: this.map.Pixel(-13, -30)
        // })
        // this.map.add(marker)
        this.arr = this.arr
          .filter((_, index) => index % 2 !== 0)
          .filter((_, index) => index % 2 !== 0)
        this.dialogVisible = true
      }
    },
    onMousemove: debounce(function (e) {
      if (this.checkFull) {
        const [px, py] = this.getContainerCoordinate(e.clientX, e.clientY)
        const [lng, lat] = this.getCoordinate(px, py)
        // this.arr.push({
        //   lineId: "line1",
        //   lng,
        //   lat,
        //   value: 0,
        // });
        this.arr.push([lng, lat])
        console.log(this.arr)
      }
    }, 10),
    getContainerCoordinate(clientX, clientY) {
      var pagex = clientX
      var pagey = clientY
      const div = this.$refs.container
      //3.获取盒子在整个页面的位置
      var xx = div.offsetLeft
      var yy = div.offsetTop
      //4.用鼠标的位置减去盒子的位置赋值给盒子的内容。
      var targetx = pagex - xx
      var targety = pagey - yy
      return [targetx, targety]
    },
    getCoordinate(px, py) {
      // 构造成 AMap.Pixel 对象后传入
      var pixel = new this.amap.Pixel(px, py)

      // 获得 AMap.LngLat 对象
      var lnglat = this.map.containerToLngLat(pixel)
      return [lnglat.lng, lnglat.lat] // 即为#container像素坐标对应的地图经纬度坐标
    },
    setPolyline(AMap, path) {
      return new AMap.Polyline({
        path,
        isOutline: false,
        strokeColor: '#009AF8',
        strokeOpacity: 0.6,
        strokeWeight: 8,
        strokeStyle: 'solid',
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 500,
        map: this.map
      })
    },
    initMap() {
      AMapLoader.load({
        key: 'a36aca73ca8aa3c36c882e22bab0e0f6', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.amap = AMap
          this.map = new AMap.Map('container', {
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 5, //初始化地图级别
            center: [105.602725, 37.076636] //初始化地图中心点位置
          })
          // 生成主河流
          this.polyline = this.setPolyline(AMap, this.roadPath)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap()
  }
}
</script>

<style scoped lang="less">
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
  position: relative;
  .button-group {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .arr-group {
    position: absolute;
    top: 40;
    left: 40;
    z-index: 999;
  }
}
</style>
