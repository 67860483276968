<template>
  <div class="container">
    <el-tabs class="tabs-part" v-if="true" v-model="activeName">
      <el-tab-pane
        v-for="(camera, index) in newCameraInfoList"
        :key="index"
        :label="`设备${index + 1}`"
        :name="camera.deviceSerial"
      >
        <LiveComponentNew
          type="jiaxing320"
          :accessToken="accessToken"
          :ref="`liveComponent_${camera.deviceSerial}`"
          :channelNoList="camera.channelNoList"
          :deviceSerial="camera.deviceSerial"
          :playerWidth="isMobile ? 350 : 850"
          :playerHeight="isMobile ? 200 : 400"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LiveComponentNew from '@/views/live/_components/LiveComponentNew'
import { getThumbnailUrl } from '@/api/yingshiLive'

const cameraInfoList = [
  {
    deviceSerial: 'FC9529336',
    name: 'FC9529336',
    channelNoList: [
      { channelNo: 1, thumbnail: '' },
      { channelNo: 2, thumbnail: '' },
      { channelNo: 3, thumbnail: '' },
      { channelNo: 4, thumbnail: '' },
      { channelNo: 5, thumbnail: '' },
      { channelNo: 6, thumbnail: '' },
      { channelNo: 7, thumbnail: '' },
      { channelNo: 8, thumbnail: '' },
      { channelNo: 9, thumbnail: '' },
      { channelNo: 10, thumbnail: '' },
      { channelNo: 11, thumbnail: '' },
      { channelNo: 12, thumbnail: '' },
      { channelNo: 13, thumbnail: '' },
      { channelNo: 14, thumbnail: '' },
      { channelNo: 15, thumbnail: '' },
      { channelNo: 16, thumbnail: '' }
    ]
  }
]

export default {
  data() {
    return {
      newCameraInfoList: [],
      activeName: 'FC9529336'
    }
  },
  components: { LiveComponentNew },
  computed: {
    accessToken() {
      return this.$store.getters.accessToken
    },
    isMobile() {
      return this.$device?.mobile
    }
  },
  methods: {
    async setToken() {
      const appKey = '7814ce88520142209684ebc6536cc2fb'
      const appSecret = '27917477d125246bb996f8fc05f173ce'
      this.$store.commit('handlerYingshiyunToken/SET_KEYINFO', {
        appKey,
        appSecret
      })
      await this.$store.dispatch('handlerYingshiyunToken/getAccessToken', {
        appKey,
        appSecret
      })
    },
    setThumbnailUrl() {
      for (const camera of cameraInfoList) {
        for (const channelNoInfo of camera.channelNoList) {
          if (channelNoInfo.thumbnail) break
          getThumbnailUrl({
            accessToken: this.accessToken,
            deviceSerial: camera.deviceSerial,
            channelNo: channelNoInfo.channelNo
          }).then((res) => (channelNoInfo.thumbnail = res.data.picUrl))
        }
      }
      this.newCameraInfoList = [...cameraInfoList]
    }
  },
  async mounted() {
    await this.setToken()
    this.setThumbnailUrl()
  }
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  padding: 16px;
  position: relative;
  .select-part {
    z-index: 999;
    position: absolute;
    top: 10px;
    right: 40px;
  }
}
/deep/.el-tabs__item {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  &.is-active {
    color: #409eff;
  }
}
</style>
