<template>
  <div>
    <video ref="video" controls></video>
    <div class="changePage" @click="$router.go(-1)">< 返回</div>
  </div>
</template>

<script>
import Hls from 'hls.js'

export default {
  data() {
    return {
      src: ''
    }
  },
  mounted() {
    const { url } = this.$route.query
    this.src = url
    const video = this.$refs.video
    if (Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(this.src)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play()
      })
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = this.src
      video.addEventListener('canplay', () => {
        video.play()
      })
    }
  }
}
</script>

<style scoped>
video {
  width: 100vw;
  height: 100vh;
}
.changePage {
  width: 160px;
  height: 160px;
  color: #fff;
  position: absolute;
  font-size: 24px;
  top: 20px;
  left: 20px;
  z-index: 999;
  cursor: pointer;
}
</style>
