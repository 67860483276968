<template>
  <div>
    <!-- 东阳 -->
    <Amap3D
      v-if="projectId === '87'"
      styleType="dark"
      :mapkey="mapkey"
      :securityJsCode="securityJsCode"
      :mapStyle="mapStyle"
      :isShow3D="true"
      :roadPath="dongYangCons.roadPath"
      :centerPos="[120.27773, 29.274904]"
      :defaultZoom="14.16"
      :locationMarkList="dongYangCons.locationMarkList"
      roadPathColor="#23baec"
      roadLineCap="kCGLineCapRound"
      showMenuKey="name"
      initMapStatus="satellite"
      :markerPathList="dongYangCons.markerPathList"
      :directionMarkList="dongYangCons.directionMarkList"
      :rotateMap="105"
    />
    <!-- 上塘至大若岩 -->
    <Amap3D
      v-if="projectId === '91'"
      styleType="dark"
      :mapkey="mapkey"
      :securityJsCode="securityJsCode"
      :mapStyle="mapStyle"
      :roadPath="daRuoYanRoadPath"
      :centerPos="[120.670738, 28.201142]"
      :defaultZoom="13.16"
      :locationMarkList="daRuoYanCons.locationMarkList"
    />
    <!-- 衢州4改3 -->
    <Amap3D
      v-if="projectId === '89'"
      styleType="dark"
      :mapkey="mapkey"
      :securityJsCode="securityJsCode"
      :mapStyle="mapStyle"
      :roadPath="quzhou4gai3Cons.roadPath"
      roadColor="#009AF8"
      :roadOpacity="0.6"
      :centerPos="[119.101792, 29.030596]"
      :defaultZoom="12.16"
      :locationMarkList="quzhou4gai3Cons.locationMarkList"
    />
    <!-- 嘉兴320 -->
    <Amap3D
      v-if="projectId === '95'"
      styleType="dark"
      :mapkey="mapkey"
      :securityJsCode="securityJsCode"
      :mapStyle="mapStyle"
      :roadPath="jiaXing320Cons.roadPath"
      roadPathColor="yellow"
      roadLineCap="kCGLineCapRound"
      :isBoatMarker="false"
      showMenuKey="name"
      initMapStatus="satellite"
      :branchPathList="jiaXing320Cons.branchPathList"
      :markerPathList="jiaXing320Cons.markerPathList"
      :directionMarkList="jiaXing320Cons.directionMarkList"
      :centerPos="[120.655768, 30.714073]"
      :defaultZoom="14.16"
      :locationMarkList="jiaXing320Cons.locationMarkList"
      :isShowPorjectInfo="true"
    />
    <!-- 常山段 -->
    <Amap3D
      v-if="projectId === '90'"
      styleType="dark"
      :mapkey="mapkey"
      :securityJsCode="securityJsCode"
      :mapStyle="mapStyle"
      :isShow3D="true"
      :isHideOperationBar="isHideOperationBar"
      :roadPath="changshanCons.roadPath"
      :centerPos="[118.56041491, 28.90189007]"
      :defaultZoom="12"
      :locationMarkList="changshanCons.locationMarkList"
      roadPathColor="#75fbfd"
      roadLineCap="kCGLineCapRound"
      showMenuKey="name"
      initMapStatus="satellite"
      :markerPathList="changshanCons.markerPathList"
      :directionMarkList="changshanCons.directionMarkList"
    />
    <!-- 柯城 -->
    <Amap3D
      v-if="projectId === '88'"
      styleType="dark"
      :mapkey="mapkey"
      :securityJsCode="securityJsCode"
      :mapStyle="mapStyle"
      :isShow3D="true"
      :roadPath="kechengCons.roadPath"
      roadPathColor="#75fbfd"
      :centerPos="[118.813305, 28.93424]"
      :defaultZoom="12.16"
      :locationMarkList="kechengCons.locationMarkList"
      initMapStatus="satellite"
    />
    <div
      v-if="!isHideOperationBar && projectId === '90'"
      class="changshan-legend"
    >
      <div class="bg"></div>
      <div
        class="legend-item"
        :class="{ legendActive: legendActive === '枢纽' }"
        @click="legendActive = '枢纽'"
      >
        <img src="@/assets/amap/changshan/枢纽.png" alt="" />
        <div class="legend-text">枢纽(4)</div>
      </div>
      <div
        class="legend-item"
        :class="{ legendActive: legendActive === '锚地' }"
        @click="legendActive = '锚地'"
      >
        <img src="@/assets/amap/changshan/锚地.png" alt="" />
        <div class="legend-text">锚地(4)</div>
      </div>
      <div
        class="legend-item"
        :class="{ legendActive: legendActive === '指北针' }"
        @click="legendActive = '指北针'"
      >
        <img src="@/assets/amap/changshan/指北针.png" alt="" />
        <div class="legend-text">指北针(1)</div>
      </div>
      <div
        class="legend-item"
        :class="{ legendActive: legendActive === '预制桥梁' }"
        @click="legendActive = '预制桥梁'"
      >
        <img src="@/assets/amap/changshan/桥梁.png" alt="" />
        <div class="legend-text">预制桥梁(5)</div>
      </div>
      <div
        class="legend-item"
        :class="{ legendActive: legendActive === '砂石料场' }"
        @click="legendActive = '砂石料场'"
      >
        <img src="@/assets/amap/changshan/石料场.png" alt="" />
        <div class="legend-text">砂石料场(2)</div>
      </div>
      <div
        class="legend-item"
        :class="{ legendActive: legendActive === '总承包部' }"
        @click="legendActive = '总承包部'"
      >
        <img src="@/assets/amap/changshan/总承包部.png" alt="" />
        <div class="legend-text">总承包部(1)</div>
      </div>
      <div
        class="legend-item"
        :class="{ legendActive: legendActive === '工区驻地' }"
        @click="legendActive = '工区驻地'"
      >
        <img src="@/assets/amap/changshan/工区驻地.png" alt="" />
        <div class="legend-text">工区驻地(7)</div>
      </div>
      <div
        class="legend-item"
        :class="{ legendActive: legendActive === '地方政府' }"
        @click="legendActive = '地方政府'"
      >
        <img src="@/assets/amap/changshan/地方政府.png" alt="" />
        <div class="legend-text">地方政府(4)</div>
      </div>
    </div>
  </div>
</template>

<script>
import Amap3D from '@/views/amap/_components/Amap3D.vue'
import dongYangCons from './_data/dongYangCons'
import daRuoYanCons from './_data/daRuoYanCons'
import quzhou4gai3Cons from './_data/quzhou4gai3'
import jiaXing320Cons from './_data/jiaXing320Cons'
import changshanCons from './_data/constants'
import kechengCons from './_data/kechengCons'

export default {
  data() {
    return {
      isHideOperationBar: false,
      mapkey: 'd22fa8f3e4fc0ae5b25c377a9fc28ab5',
      securityJsCode: '5bd8ae5e4004843e98ac253c09e68b41',
      mapStyle: 'amap://styles/6e5494d6e095a581bde7decf353dd20b',
      dongYangCons,
      daRuoYanCons,
      quzhou4gai3Cons,
      jiaXing320Cons,
      changshanCons,
      kechengCons,
      projectId: '',
      legendActive: '枢纽'
    }
  },
  components: { Amap3D },
  computed: {
    daRuoYanRoadPath() {
      return this.daRuoYanCons.roadPath.map(({ lng, lat }) => [lng, lat])
    }
  },
  created() {
    const value = this.$route.query.sendValue
    const isHideOperationBar = this.$route.query.isHideOperationBar
    if (value) {
      this.projectId = String(value)
    }
    this.isHideOperationBar = isHideOperationBar === '1'
  }
}
</script>
<style lang="less" scoped>
.changshan-legend {
  position: fixed;
  right: 32px;
  top: 163px;
  .legend-item {
    width: 198px;
    height: 40px;
    display: flex;
    align-items: center;
    background-image: url('../../assets/amap/changshan/legend-bg.png');
    background-size: cover;
    margin-bottom: 7px;
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    cursor: pointer;
    img {
      width: 24px;
      margin-right: 8px;
    }
    .legend-text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #dbeeff;
      text-align: left;
      font-style: normal;
    }
  }
  .legend-item:last-child {
    margin-bottom: 0;
  }
  .legendActive {
    background-image: url('../../assets/amap/changshan/legend-bg-active.png');
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #102a32;
    z-index: 1;
  }
}
</style>
