<template>
  <div class="container">
    <el-select
      class="select-part"
      v-model="workArea"
      placeholder="请选择工区"
      @change="workAreaSelect"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-tabs class="tabs-part" v-if="true" v-model="activeName">
      <el-tab-pane
        v-for="(camera, index) in newCameraInfoList"
        :key="index"
        :label="`设备${index + 1}`"
        :name="camera.deviceSerial"
      >
        <LiveComponentNew
          type="dongYang"
          :accessToken="accessToken"
          :ref="`liveComponent_${camera.deviceSerial}`"
          :channelNoList="camera.channelNoList"
          :deviceSerial="camera.deviceSerial"
          :playerWidth="isMobile ? 350 : 850"
          :playerHeight="isMobile ? 200 : 400"
        />
      </el-tab-pane>
    </el-tabs>
    <!-- <el-tabs
      class="tabs-part"
      v-else
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(camera, index) in cameraInfoList"
        :key="index"
        :label="camera.name"
        :name="camera.deviceSerial"
      >
        <LiveComponent
          type="dongYang"
          :accessToken="accessToken"
          :ref="`liveComponent_${camera.deviceSerial}`"
          :channelNoList="camera.channelNoList"
          :deviceSerial="camera.deviceSerial"
          :playerWidth="isMobile ? 350 : 850"
          :playerHeight="isMobile ? 200 : 400"
        />
      </el-tab-pane>
    </el-tabs> -->
  </div>
</template>

<script>
import LiveComponentNew from '@/views/live/_components/LiveComponentNew'
import { getThumbnailUrl } from '@/api/yingshiLive'

// 东阳 key
const keys = {
  area1: {
    appKey: 'bc3824dbc73748948482a84da882c5cf',
    appSecret: 'ad7fbee1fa77c3cfb2b96c2a7cde0ae9'
  },
  area2: {
    appKey: 'bd48c70d35cb40139e5270f7e668b05c',
    appSecret: 'd8057ad18bf5d82356e5e68232d1dfa9'
  },
  area3: {
    appKey: '45791b0517654bc8b3e6d3367e1128c8',
    appSecret: '63c5380c957475cd7e16f4ae732384ad'
  }
}

const cameraInfoList1 = [
  {
    deviceSerial: 'E05604630',
    name: 'E05604630',
    channelNoList: [
      { channelNo: 1, thumbnail: '' },
      { channelNo: 2, thumbnail: '' },
      { channelNo: 3, thumbnail: '' },
      { channelNo: 4, thumbnail: '' },
      { channelNo: 5, thumbnail: '' }
    ]
  }
]
const cameraInfoList2 = [
  {
    deviceSerial: 'F41770263',
    name: 'F41770263',
    channelNoList: [
      { channelNo: 1, thumbnail: '' },
      { channelNo: 2, thumbnail: '' }
    ]
  },
  {
    deviceSerial: 'E61308001',
    name: 'E61308001',
    channelNoList: [
      { channelNo: 1, thumbnail: '' },
      { channelNo: 2, thumbnail: '' },
      { channelNo: 3, thumbnail: '' }
    ]
  }
]

const cameraInfoList3 = [
  {
    deviceSerial: 'AZ7776943',
    name: 'AZ7776943',
    channelNoList: [
      { channelNo: 1, thumbnail: '' },
      { channelNo: 2, thumbnail: '' },
      { channelNo: 3, thumbnail: '' },
      { channelNo: 4, thumbnail: '' },
      { channelNo: 5, thumbnail: '' },
      { channelNo: 6, thumbnail: '' }
    ]
  }
]

export default {
  data() {
    return {
      newCameraInfoList: [],
      cameraInfoList: [],
      workArea: 1,
      activeName: 'E05604630',
      options: [
        { value: 1, label: '工区1' },
        { value: 2, label: '工区2' },
        { value: 3, label: '工区3' }
      ]
    }
  },
  components: { LiveComponentNew },
  computed: {
    accessToken() {
      return this.$store.getters.accessToken
    },
    isMobile() {
      return this.$device?.mobile
    }
  },
  methods: {
    async workAreaSelect(value) {
      this.workArea = value
      await this.setToken()
      if (value === 1) {
        this.cameraInfoList = cameraInfoList1
      } else if (value === 2) {
        this.cameraInfoList = cameraInfoList2
      } else {
        this.cameraInfoList = cameraInfoList3
      }
      this.setThumbnailUrl()
    },
    async setToken() {
      if (this.workArea === 1) {
        this.activeName = 'E05604630'
      } else if (this.workArea === 2) {
        this.activeName = 'F41770263'
      } else {
        this.activeName = 'AZ7776943'
      }
      const { appKey, appSecret } = keys[`area${this.workArea}`]
      this.$store.commit('handlerYingshiyunToken/SET_KEYINFO', {
        appKey,
        appSecret
      })
      await this.$store.dispatch('handlerYingshiyunToken/getAccessToken', {
        appKey,
        appSecret
      })
    },
    setThumbnailUrl() {
      for (const camera of this.cameraInfoList) {
        for (const channelNoInfo of camera.channelNoList) {
          if (channelNoInfo.thumbnail) break
          getThumbnailUrl({
            accessToken: this.accessToken,
            deviceSerial: camera.deviceSerial,
            channelNo: channelNoInfo.channelNo
          }).then((res) => (channelNoInfo.thumbnail = res.data.picUrl))
        }
      }
      this.newCameraInfoList = [...this.cameraInfoList]
    }
  },
  async mounted() {
    await this.setToken()
    this.workAreaSelect(this.workArea)
  }
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  padding: 16px;
  position: relative;
  .select-part {
    z-index: 999;
    position: absolute;
    top: 10px;
    right: 40px;
  }
}
/deep/.el-tabs__item {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  &.is-active {
    color: #409eff;
  }
}
</style>
