<template>
  <div class="block-container">
    <div
      v-if="!onlyPlan"
      class="block-group"
      :style="{
        marginTop: `${item.type === 'plan' ? 10 : 30}px`,
        background: `${item.type === 'plan' ? '#4A8DE0' : '#4CACA7'}`
      }"
    ></div>
    <div
      v-else
      class="block-group"
      :style="{
        marginTop: `17px`,
        background: '#4A8DE0'
      }"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    onlyPlan() {
      return window.$wujie?.props.onlyPlan
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.block-container {
  width: 100%;
  height: 55px;
  .block-group {
    width: 100%;
    height: 18px;
    border-radius: 100px;
    line-height: 18px;
    position: relative;
    .desc {
      height: 14px;
      position: absolute;
      top: 0;
      left: 10px;
      color: #ffffff;
    }
  }
}
</style>
