<template>
  <div class="container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(camera, index) in cameraInfoList"
        :key="index"
        :label="camera.deviceSerial"
        :name="camera.deviceSerial"
      >
        <LiveComponent
          type="wenZhouBei"
          :accessToken="accessToken"
          :ref="`liveComponent_${camera.deviceSerial}`"
          :channelNoList="camera.channelNoList"
          :deviceSerial="camera.deviceSerial"
          :playerWidth="isMobile ? 350 : 850"
          :playerHeight="isMobile ? 200 : 400"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LiveComponent from '@/views/live/_components/LiveComponent'

export default {
  data() {
    return {
      activeName: 'L21081319',
      cameraInfoList: [
        {
          deviceSerial: 'L21081319',
          channelNoList: [1, 2, 3]
        },
        {
          deviceSerial: 'AB8802699',
          channelNoList: [1]
        }
      ]
    }
  },
  components: { LiveComponent },
  computed: {
    accessToken() {
      return this.$store.getters.accessToken
    },
    isMobile() {
      return this.$device?.mobile
    }
  },
  methods: {
    handleClick(tab, e) {
      console.log(tab, e)
    }
  },
  async mounted() {
    // 温州北 key
    const appKey = '7bd31a0a77c649e19602f7dddfc034db'
    const appSecret = '8949e7e75f199917997d2c4499b3cf4d'
    this.$store.commit('handlerYingshiyunToken/SET_KEYINFO', {
      appKey,
      appSecret
    })
    this.$store.dispatch('handlerYingshiyunToken/getAccessToken', {
      appKey,
      appSecret
    })
  }
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  padding: 16px;
}
/deep/.el-tabs__item {
  color: #fff;
  &.is-active {
    color: #409eff;
  }
}
</style>
