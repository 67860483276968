export const locationMarkList = [
  {
    pos: [118.723712, 28.941258],
    title: '常山和柯城交界点'
  },
  { pos: [118.785854, 28.934497], title: '航埠航电枢纽' },
  { pos: [118.825765, 28.936976], title: '黄塘桥航电枢纽' }
]

export const roadPath = [
  [118.866237, 28.968229],
  [118.866044, 28.967892],
  [118.865851, 28.967554],
  [118.865658, 28.967554],
  [118.865658, 28.967386],
  [118.865658, 28.967217],
  [118.865273, 28.96688],
  [118.865273, 28.966711],
  [118.865273, 28.966543],
  [118.86508, 28.966543],
  [118.86508, 28.966374],
  [118.86508, 28.966206],
  [118.864888, 28.966206],
  [118.864888, 28.966037],
  [118.864888, 28.965531],
  [118.864888, 28.965363],
  [118.864888, 28.965194],
  [118.864888, 28.965025],
  [118.864888, 28.964857],
  [118.864888, 28.964688],
  [118.864888, 28.96452],
  [118.864695, 28.964351],
  [118.864695, 28.964182],
  [118.864695, 28.964014],
  [118.864502, 28.963845],
  [118.864502, 28.963677],
  [118.864309, 28.963508],
  [118.864309, 28.963339],
  [118.864309, 28.963171],
  [118.864117, 28.963171],
  [118.864117, 28.963002],
  [118.864117, 28.962834],
  [118.864117, 28.962665],
  [118.864117, 28.962496],
  [118.864117, 28.962328],
  [118.864117, 28.961991],
  [118.864117, 28.961822],
  [118.864117, 28.961653],
  [118.864117, 28.961485],
  [118.863924, 28.961485],
  [118.863924, 28.961316],
  [118.863924, 28.961148],
  [118.863924, 28.960979],
  [118.863924, 28.96081],
  [118.863924, 28.960642],
  [118.863924, 28.960473],
  [118.863924, 28.960304],
  [118.863731, 28.960304],
  [118.863731, 28.960136],
  [118.863731, 28.959967],
  [118.863731, 28.959799],
  [118.863539, 28.95963],
  [118.863539, 28.959461],
  [118.863539, 28.959293],
  [118.863539, 28.959124],
  [118.863539, 28.958956],
  [118.863346, 28.958787],
  [118.863346, 28.958618],
  [118.863346, 28.95845],
  [118.863346, 28.958281],
  [118.863153, 28.958281],
  [118.863153, 28.957944],
  [118.862961, 28.957775],
  [118.862961, 28.957607],
  [118.862961, 28.957438],
  [118.862768, 28.957269],
  [118.862768, 28.957101],
  [118.862768, 28.956932],
  [118.862575, 28.956932],
  [118.862575, 28.956764],
  [118.862575, 28.956595],
  [118.862575, 28.956426],
  [118.862575, 28.956258],
  [118.862382, 28.956089],
  [118.862382, 28.955921],
  [118.862382, 28.955752],
  [118.86219, 28.955752],
  [118.86219, 28.955583],
  [118.861997, 28.955583],
  [118.861997, 28.955246],
  [118.861997, 28.955077],
  [118.861804, 28.955077],
  [118.861804, 28.954909],
  [118.861804, 28.95474],
  [118.861804, 28.954572],
  [118.861804, 28.954403],
  [118.861804, 28.954234],
  [118.861804, 28.953897],
  [118.861804, 28.95356],
  [118.861804, 28.953391],
  [118.861804, 28.953223],
  [118.861804, 28.953054],
  [118.861804, 28.952885],
  [118.861612, 28.952717],
  [118.861612, 28.952548],
  [118.861612, 28.95238],
  [118.861419, 28.952211],
  [118.861419, 28.952042],
  [118.861419, 28.951874],
  [118.861226, 28.951874],
  [118.861226, 28.951705],
  [118.861033, 28.951536],
  [118.861033, 28.951368],
  [118.861033, 28.951199],
  [118.861033, 28.951031],
  [118.861033, 28.950862],
  [118.861033, 28.950693],
  [118.860841, 28.950693],
  [118.860841, 28.950525],
  [118.860841, 28.950356],
  [118.860841, 28.950187],
  [118.860841, 28.950019],
  [118.860841, 28.94985],
  [118.860841, 28.949682],
  [118.860841, 28.949513],
  [118.860841, 28.949344],
  [118.860841, 28.949176],
  [118.860841, 28.949007],
  [118.860841, 28.948838],
  [118.860841, 28.94867],
  [118.860648, 28.94867],
  [118.860648, 28.948501],
  [118.860648, 28.948333],
  [118.860648, 28.948164],
  [118.860648, 28.947995],
  [118.860455, 28.947995],
  [118.860455, 28.947827],
  [118.860455, 28.947658],
  [118.860263, 28.947489],
  [118.860263, 28.947321],
  [118.860263, 28.947152],
  [118.860263, 28.946984],
  [118.860263, 28.946815],
  [118.860263, 28.946646],
  [118.860263, 28.946478],
  [118.860263, 28.946309],
  [118.860263, 28.94614],
  [118.860263, 28.945972],
  [118.86007, 28.945972],
  [118.86007, 28.945803],
  [118.86007, 28.945634],
  [118.86007, 28.945466],
  [118.859877, 28.945466],
  [118.859685, 28.945466],
  [118.859685, 28.945297],
  [118.859492, 28.945297],
  [118.859492, 28.945129],
  [118.859492, 28.94496],
  [118.859299, 28.944791],
  [118.859106, 28.944791],
  [118.859106, 28.944623],
  [118.858914, 28.944623],
  [118.858914, 28.944454],
  [118.858721, 28.944454],
  [118.858721, 28.944285],
  [118.858528, 28.944117],
  [118.858528, 28.943948],
  [118.858336, 28.943948],
  [118.858336, 28.943779],
  [118.858143, 28.943779],
  [118.858143, 28.943442],
  [118.857757, 28.943274],
  [118.857565, 28.943274],
  [118.857565, 28.943105],
  [118.857372, 28.942936],
  [118.857372, 28.942768],
  [118.857179, 28.942768],
  [118.856987, 28.942599],
  [118.856987, 28.94243],
  [118.856794, 28.94243],
  [118.856601, 28.94243],
  [118.856601, 28.942262],
  [118.856409, 28.942599], //
  [118.856216, 28.942599],
  [118.855638, 28.942599],
  [118.855445, 28.942599],
  [118.854867, 28.942599],
  [118.854674, 28.942599],
  [118.854481, 28.942599],
  [118.854096, 28.942599],
  [118.853903, 28.942599],
  [118.853711, 28.942599],
  [118.853518, 28.942599],
  [118.853133, 28.942599],
  [118.85294, 28.942599],
  [118.85294, 28.94243],
  [118.852554, 28.94243],
  [118.852362, 28.942262],
  [118.852169, 28.942262],
  [118.851976, 28.942262],
  [118.851784, 28.942262],
  [118.851591, 28.942262],
  [118.851398, 28.942262],
  [118.851013, 28.942093],
  [118.850242, 28.941924],
  [118.850049, 28.941924],
  [118.849857, 28.941924],
  [118.849664, 28.941924],
  [118.849471, 28.941924],
  [118.849278, 28.941924],
  [118.849086, 28.941924],
  [118.848893, 28.941924],
  [118.8487, 28.941924],
  [118.848508, 28.941924],
  [118.848122, 28.941924],
  [118.847929, 28.941924],
  [118.847737, 28.941924],
  [118.847544, 28.941924],
  [118.847351, 28.941924],
  [118.847159, 28.941924],
  [118.846966, 28.941924],
  [118.846388, 28.941924],
  [118.846195, 28.941924],
  [118.84581, 28.941924],
  [118.845424, 28.941924],
  [118.845039, 28.941924],
  [118.844846, 28.941924],
  [118.844653, 28.941924],
  [118.844268, 28.941924],
  [118.844075, 28.941924],
  [118.843883, 28.941924],
  [118.84369, 28.941924],
  [118.843497, 28.941924],
  [118.843112, 28.941924],
  [118.842919, 28.941924],
  [118.842726, 28.941924],
  [118.842534, 28.941924],
  [118.842534, 28.942093],
  [118.842341, 28.942093],
  [118.841956, 28.942093],
  [118.841763, 28.942093],
  [118.841763, 28.942262],
  [118.84157, 28.94243],
  [118.841377, 28.94243],
  [118.841377, 28.942599],
  [118.841185, 28.942599],
  [118.840992, 28.942599],
  [118.840799, 28.942768],
  [118.840607, 28.942768],
  [118.840607, 28.942936],
  [118.840221, 28.943105],
  [118.840221, 28.943274],
  [118.840029, 28.943274],
  [118.839836, 28.943274],
  [118.839836, 28.943442],
  [118.839643, 28.943442],
  [118.83945, 28.943442],
  [118.83945, 28.943611],
  [118.83945, 28.943779],
  [118.83945, 28.943948],
  [118.839258, 28.943948],
  [118.839065, 28.943948],
  [118.839065, 28.944117],
  [118.838872, 28.944117],
  [118.83868, 28.944117],
  [118.83868, 28.944285],
  [118.83868, 28.944454],
  [118.838487, 28.944623],
  [118.838294, 28.944623],
  [118.838294, 28.944791],
  [118.837909, 28.944791],
  [118.837909, 28.94496],
  [118.837909, 28.945129],
  [118.837909, 28.945297],
  [118.837716, 28.945466],
  [118.837716, 28.945634],
  [118.837523, 28.945634],
  [118.837523, 28.945803],
  [118.837523, 28.945972],
  [118.837331, 28.945972],
  [118.837138, 28.94614],
  [118.837138, 28.946309],
  [118.836945, 28.946309],
  [118.836945, 28.946478],
  [118.836753, 28.946478],
  [118.83656, 28.946646],
  [118.836367, 28.946646],
  [118.836367, 28.946815],
  [118.835982, 28.946815],
  [118.835789, 28.946984],
  [118.835596, 28.946984],
  [118.835596, 28.947152],
  [118.835404, 28.947152],
  [118.835211, 28.947152],
  [118.835018, 28.947152],
  [118.83444, 28.947152],
  [118.834055, 28.946984],
  [118.833862, 28.946984],
  [118.833862, 28.946815],
  [118.833669, 28.946646],
  [118.833477, 28.946646],
  [118.833284, 28.946646],
  [118.833091, 28.946646],
  [118.832706, 28.946478],
  [118.832513, 28.946478],
  [118.832513, 28.946309],
  [118.832513, 28.94614],
  [118.832128, 28.94614],
  [118.832128, 28.945972],
  [118.831935, 28.945972],
  [118.831742, 28.945972],
  [118.831742, 28.945634],
  [118.831742, 28.945466],
  [118.831549, 28.945466],
  [118.831549, 28.945297],
  [118.831357, 28.945297],
  [118.831164, 28.945297],
  [118.830971, 28.945297],
  [118.830971, 28.945129],
  [118.830971, 28.94496],
  [118.830586, 28.94496],
  [118.830201, 28.944623],
  [118.830008, 28.944623],
  [118.829815, 28.944454],
  [118.829815, 28.944285],
  [118.829622, 28.944285],
  [118.82943, 28.944285],
  [118.82943, 28.944117],
  [118.82943, 28.943948],
  [118.829237, 28.943948],
  [118.829044, 28.943779],
  [118.829044, 28.943611],
  [118.828852, 28.943442],
  [118.828659, 28.943274],
  [118.828659, 28.943105],
  [118.828659, 28.942936],
  [118.828466, 28.942936],
  [118.828466, 28.942768],
  [118.828466, 28.942599],
  [118.828273, 28.942599],
  [118.828273, 28.94243],
  [118.828081, 28.94243],
  [118.828081, 28.942262],
  [118.827888, 28.942262],
  [118.827888, 28.942093],
  [118.827888, 28.941924],
  [118.827888, 28.941756],
  [118.827695, 28.941756],
  [118.827695, 28.941587],
  [118.827503, 28.941587],
  [118.827503, 28.941419],
  [118.827503, 28.94125],
  [118.82731, 28.94125],
  [118.827117, 28.94125],
  [118.827117, 28.940913],
  [118.827117, 28.940744],
  [118.826925, 28.940575],
  [118.826925, 28.940407],
  [118.826732, 28.940238],
  [118.826732, 28.940069],
  [118.826539, 28.940069],
  [118.826539, 28.939901],
  [118.826346, 28.939901],
  [118.826346, 28.939732],
  [118.826346, 28.939564],
  [118.826346, 28.939395],
  [118.826346, 28.939226],
  [118.826154, 28.939226],
  [118.825961, 28.938889],
  [118.825768, 28.93872],
  [118.825768, 28.938552],
  [118.825576, 28.938552],
  [118.825576, 28.938383],
  [118.825576, 28.938214],
  [118.825576, 28.938046],
  [118.825576, 28.937877],
  [118.825383, 28.937877],
  [118.82519, 28.937877],
  [118.82519, 28.937708],
  [118.82519, 28.93754],
  [118.82519, 28.937371],
  [118.82519, 28.937202],
  [118.824997, 28.937202],
  [118.824805, 28.937202],
  [118.824805, 28.937034],
  [118.824805, 28.936697],
  [118.824805, 28.936528],
  [118.824805, 28.936359],
  [118.824805, 28.936191],
  [118.824612, 28.936022],
  [118.824612, 28.935853],
  [118.824419, 28.935853],
  [118.824419, 28.935685],
  [118.824227, 28.935685],
  [118.824227, 28.935516],
  [118.824227, 28.935347],
  [118.824034, 28.935179],
  [118.824034, 28.93501],
  [118.824034, 28.934841],
  [118.824034, 28.934673],
  [118.823841, 28.934504],
  [118.823841, 28.934335],
  [118.823649, 28.934335],
  [118.823649, 28.934167],
  [118.823456, 28.933998],
  [118.823456, 28.933829],
  [118.823263, 28.933661],
  [118.823263, 28.933492],
  [118.823263, 28.933324],
  [118.823263, 28.933155],
  [118.82307, 28.933155],
  [118.82307, 28.932986],
  [118.822878, 28.932986],
  [118.822685, 28.93248],
  [118.822492, 28.93248],
  [118.822492, 28.932312],
  [118.822492, 28.932143],
  [118.822492, 28.931974],
  [118.8223, 28.931806],
  [118.822107, 28.931806],
  [118.822107, 28.931637],
  [118.821914, 28.931637],
  [118.821914, 28.931468],
  [118.821914, 28.9313],
  [118.821721, 28.931131],
  [118.821721, 28.930962],
  [118.821721, 28.930625],
  [118.821721, 28.930456],
  [118.821529, 28.930456],
  [118.821529, 28.930288],
  [118.821336, 28.930288],
  [118.821336, 28.930119],
  [118.821336, 28.92995],
  [118.821336, 28.929782],
  [118.821143, 28.929782],
  [118.821143, 28.929613],
  [118.821143, 28.929444],
  [118.821143, 28.929276],
  [118.821143, 28.929107],
  [118.820951, 28.929107],
  [118.820951, 28.928938],
  [118.820951, 28.92877],
  [118.820951, 28.928432],
  [118.820951, 28.928264],
  [118.820951, 28.928095],
  [118.820951, 28.927926],
  [118.820758, 28.927926],
  [118.820758, 28.927758],
  [118.820565, 28.927758],
  [118.820565, 28.927589],
  [118.820373, 28.927589],
  [118.820373, 28.927421],
  [118.820373, 28.927252],
  [118.82018, 28.927083],
  [118.82018, 28.926915],
  [118.82018, 28.926746],
  [118.82018, 28.926577],
  [118.82018, 28.926409],
  [118.82018, 28.92624],
  [118.82018, 28.926071],
  [118.82018, 28.925903],
  [118.82018, 28.925734],
  [118.82018, 28.925565],
  [118.819987, 28.925565],
  [118.819987, 28.925397],
  [118.819987, 28.925228],
  [118.819987, 28.925059],
  [118.819794, 28.925059],
  [118.819602, 28.925059],
  [118.819602, 28.924891],
  [118.819409, 28.924891],
  [118.819409, 28.924722],
  [118.819409, 28.924385],
  [118.819216, 28.924385],
  [118.819216, 28.924216],
  [118.819024, 28.924047],
  [118.818831, 28.923879],
  [118.818831, 28.92371],
  [118.818638, 28.92371],
  [118.818638, 28.923541],
  [118.818638, 28.923373],
  [118.818445, 28.923373],
  [118.818445, 28.923204],
  [118.818253, 28.923035],
  [118.817867, 28.923035],
  [118.817867, 28.922867],
  [118.817675, 28.922698],
  [118.817482, 28.922698],
  [118.817482, 28.922361],
  [118.817289, 28.922361],
  [118.817097, 28.922361],
  [118.817097, 28.922192],
  [118.817097, 28.922023],
  [118.817097, 28.921854],
  [118.816904, 28.921854],
  [118.816904, 28.921686],
  [118.816518, 28.921686],
  [118.816326, 28.921517],
  [118.816326, 28.92118],
  [118.816133, 28.921011],
  [118.81594, 28.920842],
  [118.81594, 28.920674],
  [118.815748, 28.920505],
  [118.815555, 28.920336],
  [118.815555, 28.920168],
  [118.815362, 28.920168],
  [118.815362, 28.919999],
  [118.815169, 28.91983],
  [118.815169, 28.919662],
  [118.814977, 28.919662],
  [118.814784, 28.919662],
  [118.814784, 28.919493],
  [118.814591, 28.919324],
  [118.814399, 28.919156],
  [118.814399, 28.918987],
  [118.814206, 28.918987],
  [118.814013, 28.918987],
  [118.814013, 28.918818],
  [118.813821, 28.918818],
  [118.813628, 28.91865],
  [118.813628, 28.918481],
  [118.813435, 28.918312],
  [118.813242, 28.918312],
  [118.813242, 28.918144],
  [118.81305, 28.918144],
  [118.812857, 28.918144],
  [118.812857, 28.917975],
  [118.812664, 28.917975],
  [118.812664, 28.917806],
  [118.812279, 28.917806],
  [118.812086, 28.917806],
  [118.811893, 28.917638],
  [118.811701, 28.917469],
  [118.811508, 28.9173],
  [118.811315, 28.917132],
  [118.811123, 28.917132],
  [118.81093, 28.917132],
  [118.81093, 28.916963],
  [118.810737, 28.916963],
  [118.810737, 28.916794],
  [118.810545, 28.916794],
  [118.810352, 28.916794],
  [118.810159, 28.916794],
  [118.809966, 28.916794],
  [118.809774, 28.916794],
  [118.809581, 28.916794],
  [118.809388, 28.916794],
  [118.809196, 28.916794],
  [118.809003, 28.916794],
  [118.80881, 28.916794],
  [118.808617, 28.916794],
  [118.808425, 28.916794],
  [118.808232, 28.916794],
  [118.808039, 28.916794],
  [118.807847, 28.916794],
  [118.807654, 28.916794],
  [118.807461, 28.916626],
  [118.807269, 28.916626],
  [118.807076, 28.916626],
  [118.806883, 28.916626],
  [118.80669, 28.916626],
  [118.806498, 28.916626],
  [118.806305, 28.916626],
  [118.806112, 28.916963],
  [118.80592, 28.916963],
  [118.805534, 28.916963],
  [118.805149, 28.916963],
  [118.804763, 28.916963],
  [118.804763, 28.917132],
  [118.804763, 28.9173],
  [118.804571, 28.9173],
  [118.804185, 28.9173],
  [118.803993, 28.9173],
  [118.803993, 28.917469],
  [118.8038, 28.917469],
  [118.803607, 28.917469],
  [118.803414, 28.917638],
  [118.803222, 28.917638],
  [118.803029, 28.917638],
  [118.802836, 28.917638],
  [118.802644, 28.917638],
  [118.802451, 28.917638],
  [118.802451, 28.917806],
  [118.802451, 28.917975],
  [118.802451, 28.918144],
  [118.802258, 28.918144],
  [118.802065, 28.918144],
  [118.802065, 28.918312],
  [118.801873, 28.918312],
  [118.80168, 28.918312],
  [118.80168, 28.918481],
  [118.801295, 28.91865],
  [118.801295, 28.918818],
  [118.801102, 28.918818],
  [118.800909, 28.918818],
  [118.800909, 28.918987],
  [118.800717, 28.918987],
  [118.800524, 28.918987],
  [118.800524, 28.919156],
  [118.800331, 28.919156],
  [118.800331, 28.919324],
  [118.800138, 28.919324],
  [118.799946, 28.919324],
  [118.799753, 28.919493],
  [118.799368, 28.919662],
  [118.799175, 28.919662],
  [118.798982, 28.919662],
  [118.798597, 28.919662],
  [118.798211, 28.919999],
  [118.798211, 28.920168],
  [118.797826, 28.920336],
  [118.797826, 28.920505],
  [118.797826, 28.920674],
  [118.797633, 28.920674],
  [118.797633, 28.920842],
  [118.797441, 28.921011],
  [118.797248, 28.921011],
  [118.797055, 28.92118],
  [118.797055, 28.921348],
  [118.797055, 28.921517],
  [118.797055, 28.921686],
  [118.796862, 28.921686],
  [118.79667, 28.921686],
  [118.79667, 28.921854],
  [118.79667, 28.922023],
  [118.796477, 28.922023],
  [118.796477, 28.922192],
  [118.796284, 28.922361],
  [118.796284, 28.922529],
  [118.796284, 28.922698],
  [118.796092, 28.922698],
  [118.796092, 28.922867],
  [118.795899, 28.923035],
  [118.795899, 28.923204],
  [118.795706, 28.923373],
  [118.795513, 28.923373],
  [118.795513, 28.923541],
  [118.795513, 28.92371],
  [118.795321, 28.92371],
  [118.795321, 28.923879],
  [118.795128, 28.924047],
  [118.795128, 28.924216],
  [118.794935, 28.924385],
  [118.794743, 28.924385],
  [118.794743, 28.924722],
  [118.794165, 28.925059],
  [118.794165, 28.925228],
  [118.794165, 28.925397],
  [118.793972, 28.925397],
  [118.793972, 28.925565],
  [118.793972, 28.925734],
  [118.793972, 28.925903],
  [118.793779, 28.925903],
  [118.793779, 28.926071],
  [118.793586, 28.92624],
  [118.793394, 28.92624],
  [118.793394, 28.926409],
  [118.793201, 28.926409],
  [118.793008, 28.926577],
  [118.793008, 28.926746],
  [118.792816, 28.926746],
  [118.792623, 28.926915],
  [118.79243, 28.927083],
  [118.792237, 28.927083],
  [118.792237, 28.927421],
  [118.792045, 28.927589],
  [118.792045, 28.927758],
  [118.791852, 28.927758],
  [118.791467, 28.928264],
  [118.791467, 28.928432],
  [118.791274, 28.928432],
  [118.791081, 28.928601],
  [118.791081, 28.92877],
  [118.791081, 28.928938],
  [118.790889, 28.928938],
  [118.790889, 28.929276],
  [118.790889, 28.929444],
  [118.790696, 28.929444],
  [118.790696, 28.929613],
  [118.790503, 28.929782],
  [118.79031, 28.929782],
  [118.79031, 28.92995],
  [118.79031, 28.930119],
  [118.790118, 28.930288],
  [118.790118, 28.930456],
  [118.790118, 28.930625],
  [118.790118, 28.930794],
  [118.789925, 28.930794],
  [118.789925, 28.930962],
  [118.789732, 28.930962],
  [118.78954, 28.931131],
  [118.78954, 28.9313],
  [118.789347, 28.9313],
  [118.789347, 28.931468],
  [118.789347, 28.931637],
  [118.789347, 28.931806],
  [118.789154, 28.931806],
  [118.788961, 28.931806],
  [118.788961, 28.931974],
  [118.788769, 28.931974],
  [118.788769, 28.932143],
  [118.788769, 28.932312],
  [118.788576, 28.932312],
  [118.788576, 28.93248],
  [118.788191, 28.932649],
  [118.787613, 28.933324],
  [118.78742, 28.933324],
  [118.78742, 28.933661],
  [118.78742, 28.933829],
  [118.787227, 28.933829],
  [118.787034, 28.933829],
  [118.787034, 28.933998],
  [118.786842, 28.933998],
  [118.786456, 28.933998],
  [118.786264, 28.934167],
  [118.786264, 28.934335],
  [118.786071, 28.934335],
  [118.785878, 28.934335],
  [118.785493, 28.934504],
  [118.785493, 28.934673],
  [118.7853, 28.934673],
  [118.7853, 28.934841],
  [118.785107, 28.934841],
  [118.784337, 28.935685],
  [118.784337, 28.935853],
  [118.784144, 28.935853],
  [118.783951, 28.936022],
  [118.783951, 28.936191],
  [118.783951, 28.936359],
  [118.783951, 28.936528],
  [118.783758, 28.936528],
  [118.783758, 28.936697],
  [118.783566, 28.936697],
  [118.783566, 28.937034],
  [118.783566, 28.937202],
  [118.783373, 28.937202],
  [118.78318, 28.937202],
  [118.78318, 28.93754],
  [118.78318, 28.937708],
  [118.782988, 28.937877],
  [118.782795, 28.937877],
  [118.782795, 28.938046],
  [118.782795, 28.938214],
  [118.782602, 28.938214],
  [118.782602, 28.938383],
  [118.782409, 28.938383],
  [118.782409, 28.938552],
  [118.782217, 28.938552],
  [118.782217, 28.93872],
  [118.782024, 28.93872],
  [118.782024, 28.938889],
  [118.781831, 28.938889],
  [118.781639, 28.938889],
  [118.781639, 28.939058],
  [118.781446, 28.939226],
  [118.781253, 28.939226],
  [118.781061, 28.939226],
  [118.780868, 28.939395],
  [118.780868, 28.939564],
  [118.780868, 28.939732],
  [118.780675, 28.939732],
  [118.780675, 28.939901],
  [118.780482, 28.939901],
  [118.780482, 28.940069],
  [118.78029, 28.940238],
  [118.780097, 28.940238],
  [118.780097, 28.940575],
  [118.779133, 28.941756],
  [118.778941, 28.941924],
  [118.778941, 28.942093],
  [118.778748, 28.942093],
  [118.778748, 28.94243],
  [118.778555, 28.94243],
  [118.778555, 28.942599],
  [118.778555, 28.942768],
  [118.778555, 28.942936],
  [118.778555, 28.943105],
  [118.778555, 28.943274],
  [118.778363, 28.943442],
  [118.778363, 28.943611],
  [118.77817, 28.943779],
  [118.777977, 28.943779],
  [118.777977, 28.943948],
  [118.777785, 28.943948],
  [118.777206, 28.944791],
  [118.777014, 28.94496],
  [118.777014, 28.945129],
  [118.777014, 28.945297],
  [118.776821, 28.945297],
  [118.776628, 28.945297],
  [118.776243, 28.945297],
  [118.77605, 28.945297],
  [118.77605, 28.945466],
  [118.775857, 28.945466],
  [118.775857, 28.945634],
  [118.775665, 28.945634],
  [118.775472, 28.945634],
  [118.775472, 28.945803],
  [118.775279, 28.945803],
  [118.775279, 28.945972],
  [118.775087, 28.945972],
  [118.774709, 28.946411],
  [118.77422, 28.946553],
  [118.774056, 28.946553],
  [118.77373, 28.946553],
  [118.773404, 28.946696],
  [118.773241, 28.946696],
  [118.773078, 28.946839],
  [118.772915, 28.946981],
  [118.772752, 28.946981],
  [118.772589, 28.947124],
  [118.772426, 28.947124],
  [118.772263, 28.947124],
  [118.771774, 28.947267],
  [118.771447, 28.947267],
  [118.771284, 28.947267],
  [118.771121, 28.947409],
  [118.770795, 28.947409],
  [118.770632, 28.947409],
  [118.770306, 28.947409],
  [118.770143, 28.947552],
  [118.769817, 28.947552],
  [118.769491, 28.947552],
  [118.769491, 28.947695],
  [118.769328, 28.947695],
  [118.769001, 28.947695],
  [118.768675, 28.947695],
  [118.768512, 28.947695],
  [118.768349, 28.947695],
  [118.768186, 28.947695],
  [118.768023, 28.947695],
  [118.76786, 28.947695],
  [118.767697, 28.947837],
  [118.767534, 28.947837],
  [118.767534, 28.94798],
  [118.767371, 28.94798],
  [118.767045, 28.948123],
  [118.766882, 28.948123],
  [118.766718, 28.948266],
  [118.766555, 28.948266],
  [118.766392, 28.948266],
  [118.766229, 28.948266],
  [118.766066, 28.948266],
  [118.765903, 28.948266],
  [118.765577, 28.948266],
  [118.765414, 28.948266],
  [118.765251, 28.948266],
  [118.765088, 28.948266],
  [118.764925, 28.948266],
  [118.764599, 28.948266],
  [118.764436, 28.948266],
  [118.764272, 28.948266],
  [118.764109, 28.948266],
  [118.763946, 28.948266],
  [118.763783, 28.948266],
  [118.76362, 28.948266],
  [118.763457, 28.948266],
  [118.763294, 28.948266],
  [118.763131, 28.948266],
  [118.762968, 28.948266],
  [118.762968, 28.948408],
  [118.762805, 28.948408],
  [118.762642, 28.948551],
  [118.762479, 28.948551],
  [118.762479, 28.948694],
  [118.762316, 28.948694],
  [118.76199, 28.948836],
  [118.761826, 28.948836],
  [118.761663, 28.948836],
  [118.761337, 28.948979],
  [118.761011, 28.948979],
  [118.760848, 28.948979],
  [118.760685, 28.948979],
  [118.760522, 28.948979],
  [118.760359, 28.948979],
  [118.760033, 28.948979],
  [118.75987, 28.948979],
  [118.759707, 28.949122],
  [118.759544, 28.949122],
  [118.75938, 28.949264],
  [118.759217, 28.949407],
  [118.758891, 28.949407],
  [118.758728, 28.949407],
  [118.758565, 28.94955],
  [118.758402, 28.94955],
  [118.758239, 28.94955],
  [118.757913, 28.94955],
  [118.757587, 28.94955],
  [118.757424, 28.94955],
  [118.757261, 28.94955],
  [118.757098, 28.94955],
  [118.756934, 28.94955],
  [118.756771, 28.94955],
  [118.756445, 28.94955],
  [118.756282, 28.94955],
  [118.755956, 28.94955],
  [118.755793, 28.94955],
  [118.75563, 28.94955],
  [118.755467, 28.94955],
  [118.755141, 28.94955],
  [118.754978, 28.94955],
  [118.754815, 28.94955],
  [118.754325, 28.94955],
  [118.754162, 28.94955],
  [118.753836, 28.94955],
  [118.753673, 28.94955],
  [118.75351, 28.94955],
  [118.753347, 28.94955],
  [118.753184, 28.94955],
  [118.753021, 28.94955],
  [118.752695, 28.94955],
  [118.752532, 28.94955],
  [118.752369, 28.94955],
  [118.752206, 28.94955],
  [118.752042, 28.94955],
  [118.751879, 28.94955],
  [118.751716, 28.94955],
  [118.751553, 28.94955],
  [118.75139, 28.94955],
  [118.751227, 28.94955],
  [118.751064, 28.94955],
  [118.750901, 28.94955],
  [118.750738, 28.94955],
  [118.750575, 28.94955],
  [118.750575, 28.949692],
  [118.750412, 28.949692],
  [118.750249, 28.949692],
  [118.750086, 28.949692],
  [118.750086, 28.949835],
  [118.749923, 28.949835],
  [118.749433, 28.949835],
  [118.748618, 28.949835],
  [118.748455, 28.949835],
  [118.748292, 28.949835],
  [118.748129, 28.949835],
  [118.747966, 28.949692],
  [118.747803, 28.949692],
  [118.747477, 28.949692],
  [118.747314, 28.949692],
  [118.74715, 28.949692],
  [118.746987, 28.949692],
  [118.746824, 28.949692],
  [118.746661, 28.949692],
  [118.746498, 28.949692],
  [118.746335, 28.949692],
  [118.746172, 28.949692],
  [118.746009, 28.949692],
  [118.745846, 28.949692],
  [118.74552, 28.949692],
  [118.745194, 28.949692],
  [118.745031, 28.949692],
  [118.744868, 28.949692],
  [118.744541, 28.949692],
  [118.744378, 28.949692],
  [118.744215, 28.949692],
  [118.744052, 28.949692],
  [118.743889, 28.949835],
  [118.743726, 28.949835],
  [118.743563, 28.949835],
  [118.7434, 28.949978],
  [118.743237, 28.949978],
  [118.743074, 28.949978],
  [118.742748, 28.949978],
  [118.742585, 28.949978],
  [118.742422, 28.949978],
  [118.742258, 28.949978],
  [118.742095, 28.949978],
  [118.741606, 28.949978],
  [118.741443, 28.949978],
  [118.74128, 28.949978],
  [118.741117, 28.949978],
  [118.740954, 28.949978],
  [118.740791, 28.949978],
  [118.740628, 28.949978],
  [118.740465, 28.949978],
  [118.740302, 28.949978],
  [118.740139, 28.949978],
  [118.739812, 28.949978],
  [118.739649, 28.949978],
  [118.739649, 28.950121],
  [118.739486, 28.950121],
  [118.739323, 28.950121],
  [118.73916, 28.950121],
  [118.738997, 28.950121],
  [118.738671, 28.950263],
  [118.738671, 28.950406],
  [118.738508, 28.950406],
  [118.738182, 28.950406],
  [118.737693, 28.950406],
  [118.737366, 28.950406],
  [118.737203, 28.950406],
  [118.73704, 28.950406],
  [118.736877, 28.950406],
  [118.736714, 28.950406],
  [118.736551, 28.950406],
  [118.736225, 28.950406],
  [118.736062, 28.950406],
  [118.735736, 28.950406],
  [118.735573, 28.950406],
  [118.73541, 28.950406],
  [118.735247, 28.950406],
  [118.735084, 28.950406],
  [118.73492, 28.950549],
  [118.734757, 28.950549],
  [118.734594, 28.950549],
  [118.734431, 28.950549],
  [118.734105, 28.950549],
  [118.733942, 28.950549],
  [118.733616, 28.950549],
  [118.733453, 28.950549],
  [118.73329, 28.950549],
  [118.733127, 28.950549],
  [118.732964, 28.950549],
  [118.732964, 28.950406],
  [118.732638, 28.950406],
  [118.732311, 28.950406],
  [118.732311, 28.950263],
  [118.732148, 28.950263],
  [118.732148, 28.950121],
  [118.731985, 28.950121],
  [118.731985, 28.949978],
  [118.731822, 28.949978],
  [118.731659, 28.949978],
  [118.731659, 28.949835],
  [118.731496, 28.949835],
  [118.731496, 28.949692],
  [118.731333, 28.949692],
  [118.731333, 28.94955],
  [118.73117, 28.949407],
  [118.731007, 28.949407],
  [118.730844, 28.949407],
  [118.730844, 28.949264],
  [118.730681, 28.949122],
  [118.730681, 28.948979],
  [118.730681, 28.948836],
  [118.730518, 28.948836],
  [118.730518, 28.948694],
  [118.730355, 28.948694],
  [118.730355, 28.948551],
  [118.730355, 28.948408],
  [118.730355, 28.948266],
  [118.730355, 28.948123],
  [118.730192, 28.948123],
  [118.730192, 28.94798],
  [118.730028, 28.94798],
  [118.730028, 28.947837],
  [118.730028, 28.947695],
  [118.729865, 28.947695],
  [118.729865, 28.947552],
  [118.729702, 28.947552],
  [118.729702, 28.947409],
  [118.729702, 28.947267],
  [118.729702, 28.947124],
  [118.729539, 28.946981],
  [118.729539, 28.946839],
  [118.729376, 28.946696],
  [118.729376, 28.946553],
  [118.729376, 28.946411],
  [118.729213, 28.946411],
  [118.729213, 28.946268],
  [118.729213, 28.946125],
  [118.72905, 28.945982],
  [118.728887, 28.94584],
  [118.728724, 28.94584],
  [118.728724, 28.945554],
  [118.728561, 28.945554],
  [118.728561, 28.945412],
  [118.728398, 28.945412],
  [118.728398, 28.945269],
  [118.728398, 28.945126],
  [118.728235, 28.944984],
  [118.728072, 28.944841],
  [118.728072, 28.944698],
  [118.727909, 28.944698],
  [118.727909, 28.944555],
  [118.727746, 28.944555],
  [118.727746, 28.94427],
  [118.727582, 28.94427],
  [118.727582, 28.944127],
  [118.727419, 28.944127],
  [118.727256, 28.943985],
  [118.727093, 28.943842],
  [118.727093, 28.943699],
  [118.726767, 28.943699],
  [118.726604, 28.943557],
  [118.726441, 28.943414],
  [118.726441, 28.943271],
  [118.726441, 28.943128],
  [118.726115, 28.943128],
  [118.725952, 28.942843],
  [118.725789, 28.9427],
  [118.725789, 28.942558],
  [118.725626, 28.942558],
  [118.725463, 28.942558],
  [118.7253, 28.942558],
  [118.725136, 28.942558],
  [118.725136, 28.942415],
  [118.724973, 28.942415],
  [118.724973, 28.942272],
  [118.72481, 28.942272],
  [118.724647, 28.942272],
  [118.724321, 28.94213],
  [118.724158, 28.94213],
  [118.723995, 28.94213],
  [118.723995, 28.941987],
  [118.723832, 28.941987],
  [118.723669, 28.941844],
  [118.723669, 28.941701],
  [118.723343, 28.941559],
  [118.72318, 28.941416],
  [118.72318, 28.941273],
  [118.723712, 28.941258]
]

export default {
  locationMarkList,
  roadPath: roadPath
    .reverse()
    .filter((_, index) => index % 2 !== 0)
    .filter((_, index) => index % 2 !== 0)
}
