<template>
  <ul>
    <div :style="{ width: `${(data.level - 1) * 8}px` }"></div>
    <i
      v-if="isParentNode(data) && !data.isExpendNode"
      class="el-icon-arrow-right icon"
      @click="onExpend(data.id, true)"
    ></i>
    <i
      v-if="isParentNode(data) && data.isExpendNode"
      class="el-icon-arrow-down icon"
      @click="onExpend(data.id, false)"
    ></i>
    <div v-if="!isParentNode(data)" class="icon"></div>
    <!-- <template v-if="getTipContent(item)"> -->
    <template v-for="item in columns">
      <template v-if="getTipContent(item)">
        <el-tooltip
          :key="item.dataIndex"
          :open-delay="1000"
          class="item"
          effect="dark"
          :content="getTipContent(item)"
          placement="top-start"
        >
          <li>{{ getTipContent(item) }}</li>
        </el-tooltip>
      </template>
      <template v-else>
        <li :key="item.dataIndex">-</li>
      </template>
    </template>
    <!-- </template> -->
    <!-- <template v-else>
        <li>{{ getTipContent(item) }}</li>
      </template> -->
  </ul>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: null
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    columns() {
      return window.$wujie?.props.ganttColumns
    },
    getDuration() {
      const { planStartTime, planEndTime } = this.data
      const startTime = new Date(planStartTime)
      const endTime = new Date(planEndTime)
      const dayNum =
        (endTime.getTime() - startTime.getTime()) / 1000 / 86400 + 1
      return dayNum > 0 ? dayNum + '天' : '不到1天'
    }
  },
  methods: {
    getTipContent({ dataIndex }) {
      if (dataIndex === 'type') {
        return this.getType(this.data[dataIndex])
      } else if (dataIndex === 'duration') {
        return this.getDuration
      } else {
        return this.data[dataIndex]
      }
    },
    isParentNode(data) {
      return data.children && data.children.length
    },
    getType(type) {
      const typeMap = {
        0: '里程碑',
        1: '一级',
        2: '二级',
        3: '三级',
        4: '四级'
      }
      return typeMap[type]
    },
    onExpend(id, isExpend) {
      window.$wujie?.props.updateChartData(id, isExpend)
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.icon {
  line-height: 55px;
  width: 40px;
  text-align: center;
  cursor: pointer;
}
ul {
  height: 55px;
  display: flex;
  align-content: center;
  justify-content: center;
  // background: #ffffff;
  li {
    width: 120px;
    height: 55px;
    font-size: 14px;
    font-family: Helvetica;
    color: #686b73;
    line-height: 55px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // border-bottom: 1px solid #e2e4e8;
  }
}
</style>
