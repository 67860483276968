<template>
  <Amap3D
    styleType="dark"
    mapkey="d22fa8f3e4fc0ae5b25c377a9fc28ab5"
    securityJsCode="5bd8ae5e4004843e98ac253c09e68b41"
    mapStyle="amap://styles/6e5494d6e095a581bde7decf353dd20b"
  ></Amap3D>
</template>

<script>
import Amap3D from '@/views/amap/3DAmap'

export default {
  components: { Amap3D }
}
</script>
