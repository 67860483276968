export const locationMarkList = [
  // { pos: [118.454916, 28.953576], title: '常山段起点' },
  { pos: [120.670354, 28.267187], type: 'bridge', title: '桐州大桥' },
  { pos: [120.682687, 28.244102], type: 'bridge', title: '八里山隧道' },
  { pos: [120.689606, 28.234178], type: 'bridge', title: '下寮乡1号桥' },
  { pos: [120.690383, 28.225956], type: 'bridge', title: '下寮乡2号桥' },
  {
    pos: [120.684634, 28.20575],
    type: 'bridge',
    title: '下寮隧道'
  },
  { pos: [120.67448, 28.195997], type: 'bridge', title: '三条岭1号大桥' },
  { pos: [120.673538, 28.194633], type: 'bridge', title: '三条岭2号大桥' },
  { pos: [120.671048, 28.188642], type: 'bridge', title: '东山下大桥' },
  { pos: [120.669742, 28.185901], type: 'bridge', title: '上下线接线终点' },
  { pos: [120.668951, 28.182907], type: 'bridge', title: '鹅浦溪桥' },
  { pos: [120.667397, 28.176475], type: 'bridge', title: '杭郭隧道' },
  { pos: [120.665883, 28.171044], type: 'bridge', title: '杭郭桥' },
  { pos: [120.663175, 28.167456], type: 'bridge', title: '大坦岭隧道' }
  // { pos: [118.864475, 28.964314], title: '柯城段终点' }
]

export const roadPath = [
  {
    lineId: 'line1',
    lng: 120.669399,
    lat: 28.271125,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66977,
    lat: 28.269913,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.670215,
    lat: 28.267111,
    value: 0
  },
  // {
  //   lineId: 'line1',
  //   lng: 120.66914,
  //   lat: 28.271048,
  //   value: 0
  // },
  {
    lineId: 'line1',
    lng: 120.67035209,
    lat: 28.25938731,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6706546,
    lat: 28.25890596,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67096649,
    lat: 28.25842932,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67128774,
    lat: 28.25795738,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67161836,
    lat: 28.25749015,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67189678,
    lat: 28.25711043,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67189678,
    lat: 28.25711043,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67155813,
    lat: 28.25757413,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67122885,
    lat: 28.25804137,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67090893,
    lat: 28.25851448,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67059838,
    lat: 28.2589923,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67035209,
    lat: 28.25938731,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67035209,
    lat: 28.25938731,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6706546,
    lat: 28.25890596,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67096649,
    lat: 28.25842932,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67128774,
    lat: 28.25795738,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67161836,
    lat: 28.25749015,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67189678,
    lat: 28.25711043,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67189735,
    lat: 28.25710964,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6873863,
    lat: 28.23630405,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67189735,
    lat: 28.25710964,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6873863,
    lat: 28.23630405,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68738539,
    lat: 28.23630508,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68754469,
    lat: 28.23608739,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68769864,
    lat: 28.23586616,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68784991,
    lat: 28.23564258,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68799582,
    lat: 28.23541782,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68813773,
    lat: 28.23518952,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68827561,
    lat: 28.23496005,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68840814,
    lat: 28.23472823,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68853666,
    lat: 28.23449405,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68865982,
    lat: 28.23425752,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68877897,
    lat: 28.23401981,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68889276,
    lat: 28.23378093,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68900254,
    lat: 28.23353969,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68910697,
    lat: 28.2332961,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68920604,
    lat: 28.23305133,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68930109,
    lat: 28.2328054,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6893908,
    lat: 28.23255828,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68947648,
    lat: 28.23231,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68955681,
    lat: 28.23205936,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68963179,
    lat: 28.23180873,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68970141,
    lat: 28.23155574,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68976702,
    lat: 28.23130276,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68982728,
    lat: 28.23104861,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68988218,
    lat: 28.23079329,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68993306,
    lat: 28.23053797,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68997859,
    lat: 28.2302803,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69001743,
    lat: 28.23002382,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69005225,
    lat: 28.22976499,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69008306,
    lat: 28.22950734,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69010717,
    lat: 28.22924852,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69012727,
    lat: 28.22898971,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69014201,
    lat: 28.22872972,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6901514,
    lat: 28.22846975,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69015544,
    lat: 28.22821096,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69015412,
    lat: 28.22795101,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69014744,
    lat: 28.22769106,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69013675,
    lat: 28.22743112,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69012071,
    lat: 28.22717236,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69009931,
    lat: 28.22691362,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69007256,
    lat: 28.22665489,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69004045,
    lat: 28.22639616,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69000433,
    lat: 28.22613863,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68996285,
    lat: 28.22588228,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68991468,
    lat: 28.22562594,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68986384,
    lat: 28.2253708,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6898063,
    lat: 28.22511566,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6897782,
    lat: 28.22499518,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6897782,
    lat: 28.22499518,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68983708,
    lat: 28.22525031,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68989194,
    lat: 28.22550545,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68994011,
    lat: 28.22576061,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68998426,
    lat: 28.22601813,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6900244,
    lat: 28.22627448,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69005784,
    lat: 28.2265332,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69008727,
    lat: 28.22679075,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69011135,
    lat: 28.22704949,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69013007,
    lat: 28.22730942,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69014344,
    lat: 28.22756818,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69015145,
    lat: 28.22782812,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69015544,
    lat: 28.22808807,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69015409,
    lat: 28.22834804,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69014604,
    lat: 28.22860683,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69013397,
    lat: 28.22886681,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69011789,
    lat: 28.22912561,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69009511,
    lat: 28.22938443,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69006698,
    lat: 28.22964325,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69003484,
    lat: 28.22990208,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68999734,
    lat: 28.23015974,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68995449,
    lat: 28.23041623,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68990762,
    lat: 28.23067272,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68985406,
    lat: 28.23092804,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68979648,
    lat: 28.23118337,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68973355,
    lat: 28.23143634,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68966526,
    lat: 28.23168933,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68959296,
    lat: 28.23194114,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68951531,
    lat: 28.23219177,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6894323,
    lat: 28.23244123,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68934394,
    lat: 28.23268952,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68925156,
    lat: 28.23293546,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68915383,
    lat: 28.2331814,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68905208,
    lat: 28.23342499,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68894498,
    lat: 28.23366622,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68883252,
    lat: 28.23390746,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68871605,
    lat: 28.23414517,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68859556,
    lat: 28.23438288,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68846972,
    lat: 28.23461705,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68833853,
    lat: 28.23485006,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68820332,
    lat: 28.2350807,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6880641,
    lat: 28.23531017,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68791952,
    lat: 28.23553611,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68777093,
    lat: 28.23576087,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68761832,
    lat: 28.23598328,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68746036,
    lat: 28.23620333,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68738539,
    lat: 28.23630508,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68738539,
    lat: 28.23630508,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68754469,
    lat: 28.23608739,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68769864,
    lat: 28.23586616,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68784991,
    lat: 28.23564258,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68799582,
    lat: 28.23541782,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68813773,
    lat: 28.23518952,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68827561,
    lat: 28.23496005,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68840814,
    lat: 28.23472823,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68853666,
    lat: 28.23449405,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68865982,
    lat: 28.23425752,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68877897,
    lat: 28.23401981,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68889276,
    lat: 28.23378093,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68900254,
    lat: 28.23353969,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68910697,
    lat: 28.2332961,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68920604,
    lat: 28.23305133,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68930109,
    lat: 28.2328054,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6893908,
    lat: 28.23255828,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68947648,
    lat: 28.23231,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68955681,
    lat: 28.23205936,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68963179,
    lat: 28.23180873,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68970141,
    lat: 28.23155574,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68976702,
    lat: 28.23130276,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68982728,
    lat: 28.23104861,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68988218,
    lat: 28.23079329,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68993306,
    lat: 28.23053797,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68997859,
    lat: 28.2302803,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69001743,
    lat: 28.23002382,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69005225,
    lat: 28.22976499,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69008306,
    lat: 28.22950734,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69010717,
    lat: 28.22924852,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69012727,
    lat: 28.22898971,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69014201,
    lat: 28.22872972,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6901514,
    lat: 28.22846975,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69015544,
    lat: 28.22821096,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69015412,
    lat: 28.22795101,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69014744,
    lat: 28.22769106,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69013675,
    lat: 28.22743112,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69012071,
    lat: 28.22717236,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69009931,
    lat: 28.22691362,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69007256,
    lat: 28.22665489,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69004045,
    lat: 28.22639616,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.69000433,
    lat: 28.22613863,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68996285,
    lat: 28.22588228,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68991468,
    lat: 28.22562594,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68986384,
    lat: 28.2253708,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6898063,
    lat: 28.22511566,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6897782,
    lat: 28.22499518,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68977861,
    lat: 28.22499411,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68617475,
    lat: 28.21017544,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68617463,
    lat: 28.21017647,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68611041,
    lat: 28.20992367,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68604082,
    lat: 28.20967088,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68596722,
    lat: 28.20941927,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68588827,
    lat: 28.20916886,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6858053,
    lat: 28.20891964,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68571564,
    lat: 28.20867161,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68562197,
    lat: 28.20842477,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68552427,
    lat: 28.2081803,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68542123,
    lat: 28.20793584,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68531283,
    lat: 28.20769494,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68520041,
    lat: 28.20745404,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68508264,
    lat: 28.2072167,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68495952,
    lat: 28.20697937,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68483238,
    lat: 28.20674558,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68470123,
    lat: 28.20651299,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68456472,
    lat: 28.20628277,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6844242,
    lat: 28.20605375,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68427966,
    lat: 28.20582827,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68412977,
    lat: 28.20560398,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68397586,
    lat: 28.20538325,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68381793,
    lat: 28.20516371,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68365466,
    lat: 28.20494772,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68348737,
    lat: 28.20473292,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68331606,
    lat: 28.20452168,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68314073,
    lat: 28.2043128,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6829614,
    lat: 28.20410748,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68277804,
    lat: 28.20390453,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68259067,
    lat: 28.20370395,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68239929,
    lat: 28.20350693,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68220389,
    lat: 28.20331227,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68200581,
    lat: 28.20312117,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68180238,
    lat: 28.20293244,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68159628,
    lat: 28.20274726,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68138615,
    lat: 28.20256563,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68117202,
    lat: 28.20238638,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68095386,
    lat: 28.20221186,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68073437,
    lat: 28.20203971,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68050953,
    lat: 28.20187111,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68028201,
    lat: 28.20170607,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68005181,
    lat: 28.20154457,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67981759,
    lat: 28.20138663,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6795807,
    lat: 28.20123342,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67934114,
    lat: 28.20108258,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67909889,
    lat: 28.2009353,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67885263,
    lat: 28.20079274,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6786037,
    lat: 28.20065374,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67835342,
    lat: 28.20051829,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67809913,
    lat: 28.20038639,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6779238,
    lat: 28.20029808,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6779238,
    lat: 28.20029808,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67817943,
    lat: 28.20042761,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67843238,
    lat: 28.20055951,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67868266,
    lat: 28.20069614,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67893026,
    lat: 28.20083751,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67917518,
    lat: 28.20098125,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67941608,
    lat: 28.20112972,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67965565,
    lat: 28.20128056,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6798912,
    lat: 28.20143613,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68012408,
    lat: 28.20159526,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68035428,
    lat: 28.20175794,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68058046,
    lat: 28.20192417,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68080263,
    lat: 28.20209277,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68102346,
    lat: 28.2022661,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68123893,
    lat: 28.20244299,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68145173,
    lat: 28.20262224,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68166052,
    lat: 28.20280505,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68186662,
    lat: 28.20299141,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68206738,
    lat: 28.20318015,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68226545,
    lat: 28.20337243,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68245951,
    lat: 28.20356827,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68264956,
    lat: 28.20376648,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68283559,
    lat: 28.20396707,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68301894,
    lat: 28.2041712,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68319694,
    lat: 28.2043777,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68337093,
    lat: 28.20458776,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6835409,
    lat: 28.20480019,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68370551,
    lat: 28.20501499,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68386745,
    lat: 28.20523217,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68402404,
    lat: 28.20545171,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68417661,
    lat: 28.20567363,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68432516,
    lat: 28.2058991,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6844697,
    lat: 28.20612576,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68460888,
    lat: 28.20635479,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68474272,
    lat: 28.20658501,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68487253,
    lat: 28.20681879,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68499833,
    lat: 28.20705375,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68512012,
    lat: 28.20729109,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68523521,
    lat: 28.20752962,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68534628,
    lat: 28.20777052,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68545335,
    lat: 28.20801261,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68555505,
    lat: 28.20825589,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68565275,
    lat: 28.20850154,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68574374,
    lat: 28.20874838,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68583207,
    lat: 28.20899641,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6859137,
    lat: 28.20924682,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68599131,
    lat: 28.20949723,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68606357,
    lat: 28.20975002,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68613048,
    lat: 28.21000281,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68617463,
    lat: 28.21017647,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68617463,
    lat: 28.21017647,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68611041,
    lat: 28.20992367,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68604082,
    lat: 28.20967088,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68596722,
    lat: 28.20941927,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68588827,
    lat: 28.20916886,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6858053,
    lat: 28.20891964,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68571564,
    lat: 28.20867161,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68562197,
    lat: 28.20842477,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68552427,
    lat: 28.2081803,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68542123,
    lat: 28.20793584,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68531283,
    lat: 28.20769494,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68520041,
    lat: 28.20745404,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68508264,
    lat: 28.2072167,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68495952,
    lat: 28.20697937,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68483238,
    lat: 28.20674558,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68470123,
    lat: 28.20651299,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68456472,
    lat: 28.20628277,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6844242,
    lat: 28.20605375,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68427966,
    lat: 28.20582827,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68412977,
    lat: 28.20560398,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68397586,
    lat: 28.20538325,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68381793,
    lat: 28.20516371,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68365466,
    lat: 28.20494772,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68348737,
    lat: 28.20473292,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68331606,
    lat: 28.20452168,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68314073,
    lat: 28.2043128,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6829614,
    lat: 28.20410748,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68277804,
    lat: 28.20390453,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68259067,
    lat: 28.20370395,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68239929,
    lat: 28.20350693,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68220389,
    lat: 28.20331227,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68200581,
    lat: 28.20312117,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68180238,
    lat: 28.20293244,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68159628,
    lat: 28.20274726,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68138615,
    lat: 28.20256563,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68117202,
    lat: 28.20238638,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68095386,
    lat: 28.20221186,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68073437,
    lat: 28.20203971,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68050953,
    lat: 28.20187111,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68028201,
    lat: 28.20170607,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.68005181,
    lat: 28.20154457,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67981759,
    lat: 28.20138663,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6795807,
    lat: 28.20123342,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67934114,
    lat: 28.20108258,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67909889,
    lat: 28.2009353,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67885263,
    lat: 28.20079274,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6786037,
    lat: 28.20065374,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67835342,
    lat: 28.20051829,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67809913,
    lat: 28.20038639,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6779238,
    lat: 28.20029808,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67792396,
    lat: 28.20029754,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67675514,
    lat: 28.19971903,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67792396,
    lat: 28.20029754,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67675514,
    lat: 28.19971903,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67675069,
    lat: 28.19971682,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.676698,
    lat: 28.19969074,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67669786,
    lat: 28.19969169,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67664433,
    lat: 28.19966578,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67664433,
    lat: 28.19966578,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67659213,
    lat: 28.1996387,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67659213,
    lat: 28.1996387,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67653993,
    lat: 28.19961279,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67653993,
    lat: 28.19961279,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6764864,
    lat: 28.19958689,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6764864,
    lat: 28.19958689,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6764342,
    lat: 28.19955981,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6764342,
    lat: 28.19955981,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67638201,
    lat: 28.1995339,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67638201,
    lat: 28.1995339,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67632981,
    lat: 28.19950682,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67632981,
    lat: 28.19950682,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67627762,
    lat: 28.19948091,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67627762,
    lat: 28.19948091,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67622542,
    lat: 28.19945382,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67622542,
    lat: 28.19945382,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67617322,
    lat: 28.19942674,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67617322,
    lat: 28.19942674,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67612103,
    lat: 28.19939847,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67612103,
    lat: 28.19939847,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67606883,
    lat: 28.19937138,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67606883,
    lat: 28.19937138,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67601797,
    lat: 28.19934311,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67601797,
    lat: 28.19934311,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67596712,
    lat: 28.19931602,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67596712,
    lat: 28.19931602,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67591492,
    lat: 28.19928776,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67591492,
    lat: 28.19928776,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67587745,
    lat: 28.19926655,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67587745,
    lat: 28.19926655,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67587343,
    lat: 28.1992642,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67587343,
    lat: 28.1992642,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67574227,
    lat: 28.19918998,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67561245,
    lat: 28.19911222,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67548531,
    lat: 28.19903327,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67535817,
    lat: 28.19895313,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6752337,
    lat: 28.19886945,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67511191,
    lat: 28.19878458,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67499012,
    lat: 28.19869852,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67487101,
    lat: 28.19860892,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67475324,
    lat: 28.19851931,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6746368,
    lat: 28.19842734,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67452305,
    lat: 28.198333,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67441063,
    lat: 28.19823747,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67430089,
    lat: 28.19813957,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67419248,
    lat: 28.19804049,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67408676,
    lat: 28.19793904,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67398237,
    lat: 28.19783759,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67387932,
    lat: 28.19773259,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67377895,
    lat: 28.19762758,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67368125,
    lat: 28.1975202,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67358623,
    lat: 28.19741164,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67349121,
    lat: 28.19730189,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67340021,
    lat: 28.19718978,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67331054,
    lat: 28.19707766,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67322356,
    lat: 28.19696317,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67313924,
    lat: 28.19684749,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67313523,
    lat: 28.19684041,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67313523,
    lat: 28.19684041,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67321954,
    lat: 28.19695726,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67330653,
    lat: 28.19707057,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67339486,
    lat: 28.19718388,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6734872,
    lat: 28.19729599,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67358088,
    lat: 28.19740574,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6736759,
    lat: 28.1975143,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67377359,
    lat: 28.19762168,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67387396,
    lat: 28.19772787,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67397701,
    lat: 28.19783169,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67408006,
    lat: 28.19793433,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67418713,
    lat: 28.19803459,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67429553,
    lat: 28.19813485,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67440527,
    lat: 28.19823157,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67451769,
    lat: 28.19832828,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67463145,
    lat: 28.19842144,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67474655,
    lat: 28.1985146,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67486432,
    lat: 28.1986042,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67498343,
    lat: 28.19869381,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67510522,
    lat: 28.19877986,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67522701,
    lat: 28.19886473,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67535148,
    lat: 28.19894842,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67547862,
    lat: 28.19902973,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67560576,
    lat: 28.19910868,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67573558,
    lat: 28.19918526,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6758654,
    lat: 28.19926066,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67587343,
    lat: 28.1992642,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67587343,
    lat: 28.1992642,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67574227,
    lat: 28.19918998,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67561245,
    lat: 28.19911222,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67548531,
    lat: 28.19903327,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67535817,
    lat: 28.19895313,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6752337,
    lat: 28.19886945,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67511191,
    lat: 28.19878458,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67499012,
    lat: 28.19869852,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67487101,
    lat: 28.19860892,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67475324,
    lat: 28.19851931,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6746368,
    lat: 28.19842734,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67452305,
    lat: 28.198333,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67441063,
    lat: 28.19823747,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67430089,
    lat: 28.19813957,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67419248,
    lat: 28.19804049,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67408676,
    lat: 28.19793904,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67398237,
    lat: 28.19783759,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67387932,
    lat: 28.19773259,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67377895,
    lat: 28.19762758,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67368125,
    lat: 28.1975202,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67358623,
    lat: 28.19741164,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67349121,
    lat: 28.19730189,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67340021,
    lat: 28.19718978,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67331054,
    lat: 28.19707766,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67322356,
    lat: 28.19696317,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67313924,
    lat: 28.19684749,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67313523,
    lat: 28.19684041,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67313523,
    lat: 28.19684041,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67313122,
    lat: 28.19683569,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67313122,
    lat: 28.19683569,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67310846,
    lat: 28.19680263,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67310846,
    lat: 28.19680263,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67307635,
    lat: 28.19675778,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67307635,
    lat: 28.19675778,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67304423,
    lat: 28.19671174,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67304423,
    lat: 28.19671174,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67301345,
    lat: 28.19666688,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67301345,
    lat: 28.19666688,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67298267,
    lat: 28.19662084,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67298267,
    lat: 28.19662084,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67295055,
    lat: 28.1965748,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67295055,
    lat: 28.1965748,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67292111,
    lat: 28.19652876,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67292111,
    lat: 28.19652876,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67289033,
    lat: 28.19648272,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67289033,
    lat: 28.19648272,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67285955,
    lat: 28.19643668,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67285955,
    lat: 28.19643668,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67283011,
    lat: 28.19639064,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67283011,
    lat: 28.19639064,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67279933,
    lat: 28.19634342,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67279933,
    lat: 28.19634342,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67276989,
    lat: 28.19629737,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67276989,
    lat: 28.19629737,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67274044,
    lat: 28.19625133,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67274044,
    lat: 28.19625133,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.672711,
    lat: 28.19620411,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.672711,
    lat: 28.19620411,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67268156,
    lat: 28.19615806,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67268156,
    lat: 28.19615806,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67265212,
    lat: 28.19611084,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67265246,
    lat: 28.19611052,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.672623,
    lat: 28.1960639,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67262051,
    lat: 28.19605996,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67191617,
    lat: 28.19494531,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67262051,
    lat: 28.19605996,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.67191617,
    lat: 28.19494531,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66426052,
    lat: 28.16982847,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6642575,
    lat: 28.16982483,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66422085,
    lat: 28.16978051,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66422064,
    lat: 28.16978066,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66418317,
    lat: 28.16973698,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66418317,
    lat: 28.16973698,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66414703,
    lat: 28.16969212,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66414703,
    lat: 28.16969212,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6641109,
    lat: 28.16964844,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6641109,
    lat: 28.16964844,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66407342,
    lat: 28.16960359,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66407342,
    lat: 28.16960359,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66403729,
    lat: 28.16955873,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66403729,
    lat: 28.16955873,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66400115,
    lat: 28.16951386,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66400115,
    lat: 28.16951386,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66396502,
    lat: 28.16947019,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66396502,
    lat: 28.16947019,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66392888,
    lat: 28.16942532,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66392888,
    lat: 28.16942532,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66389409,
    lat: 28.16938046,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66389409,
    lat: 28.16938046,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66385795,
    lat: 28.16933442,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66385795,
    lat: 28.16933442,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66382316,
    lat: 28.16928955,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66382316,
    lat: 28.16928955,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66378836,
    lat: 28.16924469,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66378836,
    lat: 28.16924469,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66375356,
    lat: 28.16919864,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66375356,
    lat: 28.16919864,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66371877,
    lat: 28.16915378,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66371877,
    lat: 28.16915378,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66368397,
    lat: 28.16910773,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66368397,
    lat: 28.16910773,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6636746,
    lat: 28.16909474,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6636746,
    lat: 28.16909474,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66367192,
    lat: 28.1690912,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66367192,
    lat: 28.1690912,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66358226,
    lat: 28.16896959,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6634966,
    lat: 28.1688456,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66341229,
    lat: 28.16871925,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66333065,
    lat: 28.16859289,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66325169,
    lat: 28.16846534,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66317541,
    lat: 28.16833661,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6631018,
    lat: 28.16820669,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66302953,
    lat: 28.1680744,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66296128,
    lat: 28.1679421,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66289571,
    lat: 28.16780862,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66283147,
    lat: 28.16767395,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66277125,
    lat: 28.16753927,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6627137,
    lat: 28.16740222,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6626575,
    lat: 28.16726517,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66260531,
    lat: 28.16712693,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66255579,
    lat: 28.16698869,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66250896,
    lat: 28.16684925,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66246346,
    lat: 28.16670864,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66242198,
    lat: 28.16656801,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66238317,
    lat: 28.1664262,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66234704,
    lat: 28.16628319,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66231493,
    lat: 28.16614137,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66228416,
    lat: 28.16599836,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66225606,
    lat: 28.16585416,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66223198,
    lat: 28.16570995,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66221057,
    lat: 28.16556574,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66219184,
    lat: 28.16542034,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66217579,
    lat: 28.16527611,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66216242,
    lat: 28.1651307,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66215172,
    lat: 28.16498529,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6621437,
    lat: 28.16483987,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6621397,
    lat: 28.16469326,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66213837,
    lat: 28.16454782,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66213972,
    lat: 28.16440238,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66214374,
    lat: 28.16425694,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66215045,
    lat: 28.16411031,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66215983,
    lat: 28.16396485,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66217322,
    lat: 28.16381939,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66218796,
    lat: 28.1636751,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66220671,
    lat: 28.16352963,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66222813,
    lat: 28.16338534,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66223349,
    lat: 28.16335458,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66223349,
    lat: 28.16335458,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66221072,
    lat: 28.16350006,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66219197,
    lat: 28.16364435,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6621759,
    lat: 28.16378982,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66216251,
    lat: 28.16393528,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66215179,
    lat: 28.16408074,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66214508,
    lat: 28.16422619,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66213972,
    lat: 28.16437164,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66213837,
    lat: 28.16451708,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6621397,
    lat: 28.16466369,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6621437,
    lat: 28.16480912,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66215038,
    lat: 28.16495455,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66215974,
    lat: 28.16509997,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66217178,
    lat: 28.16524538,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66218783,
    lat: 28.16539078,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66220656,
    lat: 28.165535,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66222796,
    lat: 28.1656804,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66225071,
    lat: 28.16582461,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6622788,
    lat: 28.16596762,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66230824,
    lat: 28.16611182,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66234035,
    lat: 28.16625365,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66237648,
    lat: 28.16639665,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66241395,
    lat: 28.16653846,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66245543,
    lat: 28.16667909,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66249825,
    lat: 28.16681971,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66254509,
    lat: 28.16695915,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6625946,
    lat: 28.16709858,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66264679,
    lat: 28.16723682,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66270166,
    lat: 28.16737387,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6627592,
    lat: 28.16751092,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66281943,
    lat: 28.16764678,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66288232,
    lat: 28.16778145,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6629479,
    lat: 28.16791493,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66301615,
    lat: 28.16804723,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66308574,
    lat: 28.16817834,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66315935,
    lat: 28.16830945,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66323563,
    lat: 28.16843818,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66331325,
    lat: 28.16856691,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66339489,
    lat: 28.16869327,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66347787,
    lat: 28.16881963,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66356486,
    lat: 28.16894361,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66365319,
    lat: 28.16906641,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66367192,
    lat: 28.1690912,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66367192,
    lat: 28.1690912,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66358226,
    lat: 28.16896959,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6634966,
    lat: 28.1688456,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66341229,
    lat: 28.16871925,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66333065,
    lat: 28.16859289,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66325169,
    lat: 28.16846534,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66317541,
    lat: 28.16833661,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6631018,
    lat: 28.16820669,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66302953,
    lat: 28.1680744,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66296128,
    lat: 28.1679421,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66289571,
    lat: 28.16780862,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66283147,
    lat: 28.16767395,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66277125,
    lat: 28.16753927,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6627137,
    lat: 28.16740222,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6626575,
    lat: 28.16726517,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66260531,
    lat: 28.16712693,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66255579,
    lat: 28.16698869,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66250896,
    lat: 28.16684925,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66246346,
    lat: 28.16670864,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66242198,
    lat: 28.16656801,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66238317,
    lat: 28.1664262,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66234704,
    lat: 28.16628319,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66231493,
    lat: 28.16614137,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66228416,
    lat: 28.16599836,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66225606,
    lat: 28.16585416,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66223198,
    lat: 28.16570995,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66221057,
    lat: 28.16556574,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66219184,
    lat: 28.16542034,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66217579,
    lat: 28.16527611,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66216242,
    lat: 28.1651307,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66215172,
    lat: 28.16498529,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6621437,
    lat: 28.16483987,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.6621397,
    lat: 28.16469326,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66213837,
    lat: 28.16454782,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66213972,
    lat: 28.16440238,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66214374,
    lat: 28.16425694,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66215045,
    lat: 28.16411031,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66215983,
    lat: 28.16396485,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66217322,
    lat: 28.16381939,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66218796,
    lat: 28.1636751,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66220671,
    lat: 28.16352963,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66222813,
    lat: 28.16338534,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66223349,
    lat: 28.16335458,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66223349,
    lat: 28.16335458,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66223349,
    lat: 28.16334985,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66223349,
    lat: 28.16334985,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66223617,
    lat: 28.16333566,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66223617,
    lat: 28.16333566,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66224554,
    lat: 28.16328125,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66224554,
    lat: 28.16328125,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66225491,
    lat: 28.16322684,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66225491,
    lat: 28.16322684,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66226429,
    lat: 28.16317243,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66226429,
    lat: 28.16317243,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.662275,
    lat: 28.16311802,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.662275,
    lat: 28.16311802,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66228437,
    lat: 28.16306361,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66228437,
    lat: 28.16306361,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66229508,
    lat: 28.16301038,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66229508,
    lat: 28.16301038,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66230579,
    lat: 28.16295597,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66230579,
    lat: 28.16295597,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66231784,
    lat: 28.16290155,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66231784,
    lat: 28.16290155,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66232856,
    lat: 28.16284832,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66232856,
    lat: 28.16284832,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66234061,
    lat: 28.16279391,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66234061,
    lat: 28.16279391,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66235266,
    lat: 28.16274068,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66235266,
    lat: 28.16274068,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66236337,
    lat: 28.16268627,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66236337,
    lat: 28.16268627,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66237542,
    lat: 28.16263303,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66237542,
    lat: 28.16263303,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66238747,
    lat: 28.16257862,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66238747,
    lat: 28.16257862,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66239952,
    lat: 28.16252539,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66240033,
    lat: 28.16252428,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66241235,
    lat: 28.16247045,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66241334,
    lat: 28.16246603,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66298242,
    lat: 28.15991939,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66241334,
    lat: 28.16246603,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66298195,
    lat: 28.15992053,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66306362,
    lat: 28.15953371,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66313861,
    lat: 28.15914572,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66320556,
    lat: 28.15875656,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66326582,
    lat: 28.15836623,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66331671,
    lat: 28.15797591,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66336091,
    lat: 28.15758324,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66337698,
    lat: 28.1574283,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66337698,
    lat: 28.1574283,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66340913,
    lat: 28.15711488,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66344796,
    lat: 28.15680262,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66349349,
    lat: 28.15649036,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66354438,
    lat: 28.15617808,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66360196,
    lat: 28.15586815,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.66366489,
    lat: 28.15555821,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.663693,
    lat: 28.15543518,
    value: 0
  },
  {
    lineId: 'line1',
    lng: 120.668829,
    lat: 28.147528,
    value: 0
  }
]

export default {
  locationMarkList,
  roadPath
}
