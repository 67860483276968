<template>
  <div>
    <div class="start-btn btn1"><a @click="onStart">开始镜头追踪</a></div>
    <div class="start-btn btn2"><a @click="onPause">暂停镜头追踪</a></div>
    <div class="start-btn btn3"><a @click="onCancel">停止镜头追踪</a></div>
    <div id="map">
      <div class="operation-container">
        <div class="operation-icon">
          <img
            @mouseover="isStatusMenuShow = true"
            @mouseleave="isStatusMenuShow = false"
            src="../../assets/amap/changeMapStatus.png"
            alt=""
          />
          <ul
            @mouseover="isStatusMenuShow = true"
            @mouseleave="isStatusMenuShow = false"
            class="menu-group map-status"
            v-show="isStatusMenuShow"
          >
            <li
              class="menu-item"
              @click="setMapStatus(statusItem.type)"
              v-for="statusItem in statusList"
              :key="statusItem.type"
            >
              {{ statusItem.title }}
            </li>
          </ul>
        </div>
        <div class="operation-icon">
          <img
            @mouseover="isDbMenuShow = true"
            @mouseleave="isDbMenuShow = false"
            class="operation-icon"
            src="../../assets/amap/queryDb.png"
            alt=""
          />
          <ul
            @mouseover="isDbMenuShow = true"
            @mouseleave="isDbMenuShow = false"
            class="menu-group map-db"
            v-show="isDbMenuShow"
          >
            <li
              class="menu-item"
              @click="queryBd(loca.pos)"
              v-for="loca in locationMarkList"
              :key="loca.title"
            >
              {{ loca.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { roadPath, locationMarkList } from './_data/constants'

export default {
  data() {
    return {
      locationMarkList,
      isDbMenuShow: false,
      isStatusMenuShow: false,
      finished: true,
      polyline: {}, // 线路
      loca: {}, // 控制3d对象
      boatMarker: {}, // 标签图标
      map: {}, // 地图
      mapStatus: '2D',
      satelliteLayer: {}, // 卫星图层
      statusList: [
        { type: '2D', title: '平面图' },
        { type: '2.5D', title: '2.5D图' },
        { type: 'satellite', title: '卫星图' }
      ]
      // mask: []
    }
  },
  computed: {
    riverPath() {
      return roadPath
        .filter((_, index) => index % 2 !== 0)
        .filter((_, index) => index % 2 !== 0)
    }
  },
  methods: {
    initMap() {
      window._AMapSecurityConfig = {
        // serviceHost: '您的代理服务器域名或地址/_AMapService',
        securityJsCode: 'f917f179558f385f7248b13cad67c294'
      }

      AMapLoader.load({
        key: 'a36aca73ca8aa3c36c882e22bab0e0f6', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.DistrictSearch',
          'AMap.ControlBar',
          'AMap.Object3DLayer'
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        Loca: {
          //是否加载 Loca,缺省不加载
          version: '2.0.0' //Loca 版本,缺省1.3.2
        }
      })
        .then((AMap) => {
          const controlBar = new AMap.ControlBar({
            position: { top: '10px', right: '10px' }
          })
          this.satelliteLayer = new AMap.TileLayer.Satellite()
          this.map = new AMap.Map('map', {
            terrain: true,
            viewMode: '3D',
            zoom: 12,
            center: [118.723712, 28.941258],
            mapStyle: 'amap://styles/ca7141cca7380d87c2866f3f540a2df2',
            // pitch: 0,
            // rotation: 0,
            // showLabel: true,
            showBuildingBlock: true
            // dragEnable: true,
            // zoomEnable: true
            // layers: [
            //   new AMap.TileLayer.RoadNet({
            //     //rejectMapMask:true
            //   }),
            //   new AMap.TileLayer.Satellite()
            // ]
          })
          this.map.addControl(controlBar) // 添加地图控制器

          // // 创建Object3DLayer图层
          // var object3Dlayer = new AMap.Object3DLayer()
          // this.map.add(object3Dlayer)

          // // 加载AMap.GltfLoader插件
          // AMap.plugin(['AMap.GltfLoader'], function () {
          //   // 创建AMap.GltfLoader插件实例
          //   var gltf = new AMap.GltfLoader()

          //   // 调用load方法，加载 glTF 模型资源
          //   var urlDuck = '/models/boat.gltf' // 模型资源文件路径，远程/本地文件均可
          //   gltf.load(urlDuck, function (gltfCity) {
          //     // gltfCity 为解析后的gltf对象
          //     console.log(gltfCity)
          //   })
          // })

          this.loca = new Loca.Container({
            map: this.map
          })

          // 生成地标箭头
          for (let loca of locationMarkList) {
            this.setMarker(AMap, '<div class="db-marker"></div>', loca)
          }
          // 生成小船
          this.boatMarker = this.setMarker(
            AMap,
            '<div class="boat-marker"></div>',
            {
              pos: this.riverPath[0]
            }
          )
          // 生成主河流
          this.polyline = this.setPolyline(AMap, this.riverPath)
          this.loca.animate.start()
        })
        .catch((e) => {
          console.log(e)
        })
    },
    run() {
      if (!this.finished) {
        // 获取地图的中心点（随着相机运动，每一帧的中心点数据都会更新）
        var center = this.map.getCenter().toArray()
        this.boatMarker.setPosition(center) // 设置图标的位置到中心点
      }
      requestAnimationFrame(this.run)
    },
    onStart() {
      this.finished = false
      this.loca.viewControl.addTrackAnimate(
        {
          path: this.riverPath, // 镜头轨迹，二维数组，支持海拔
          duration: 120000, // 时长
          timing: [
            [0, 0.3],
            [1, 0.7]
          ], // 速率控制器
          rotationSpeed: 10 // 每秒旋转多少度
        },
        function () {
          this.finished = true
          console.log('完成')
        }
      )
    },
    setMarker(AMap, content, { pos, title }) {
      const marker = new AMap.Marker({
        position: pos,
        content,
        anchor: 'bottom-center',
        map: this.map
      })
      if (title) {
        marker.setLabel({
          direction: 'up',
          offset: new AMap.Pixel(10, 0), //设置文本标注偏移量
          content: `<div class='db-info'>${title}</div>` //设置文本标注内容
        })
      }
      return marker
    },
    setPolyline(AMap, path) {
      return new AMap.Polyline({
        path,
        isOutline: false,
        strokeColor: '#009AF8',
        strokeOpacity: 0.6,
        strokeWeight: 8,
        strokeStyle: 'solid',
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 500,
        map: this.map
      })
    },
    // 设置地图状态
    setMapStatus(status) {
      this.isStatusMenuShow = false
      switch (status) {
        case '2D':
          console.log(this.map.getLayers())
          this.map.remove([this.satelliteLayer])
          this.map.setPitch(0, false, 500)
          break
        case '2.5D':
          this.map.setPitch(55, false, 500)
          break
        default:
          this.map.setLayers([this.satelliteLayer])
          break
      }
    },
    queryBd(pos) {
      this.isDbMenuShow = false
      this.map.setZoomAndCenter(15, pos)
    },
    onPause() {},
    onCancel() {}
  },
  watch: {
    mapStatus(newV) {
      this.setMapStatus(newV)
    }
  },
  mounted() {
    this.initMap()
    // this.run()
  }
}
</script>

<style lang="less" scoped>
#map {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 800px;
  position: relative;
  margin: 0 auto;
  .operation-container {
    height: 64px;
    padding: 0 24px;
    background: rgba(255, 255, 255, 0.11) rgba(255, 255, 255, 0.12);
    border: 1px solid;
    border-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0)
      )
      1 1;
    backdrop-filter: blur(30px);
    position: absolute;
    left: 50%;
    bottom: 50px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .operation-icon {
      width: 30px;
      height: 30px;
      margin-right: 24px;
      cursor: pointer;
      position: relative;
      &.last-of-type {
        margin-right: 0px;
      }
      .map-status {
        bottom: 40px;
      }
      .map-db {
        bottom: 200px;
      }
      .menu-group {
        position: absolute;
        width: 150px;
        height: 120px;
        background: #eceff3;
        // bottom: 90px;
        left: -55px;
        z-index: 999;
        li {
          width: 100%;
          height: 40px;
          text-align: center;
          background: #eceff3;
          font-size: 16px;
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          font-weight: 400;
          color: #343434;
          line-height: 40px;
          &:hover {
            background: #008e8e;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.demo-title {
  position: absolute;
  top: 50px;
  left: 200px;
  z-index: 1;
}

h1 {
  margin: 0;
  color: rgba(255, 255, 255, 0.75);
}

h3 {
  font-weight: normal;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.75);
}

.start-btn {
  position: absolute;
  /* bottom: 20px; */
  right: 20px;
  padding: 0 18px;
  height: 30px;
  background-color: #1a66ff;
  border-radius: 5px;
  z-index: 1;
  cursor: pointer;
}
.btn1 {
  bottom: 140px;
}
.btn2 {
  bottom: 80px;
}
.btn3 {
  bottom: 20px;
}

.start-btn > a {
  color: #fff;
  display: block;
  height: 100%;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
}
</style>

<style>
.db-marker {
  width: 40px;
  height: 50px;
  background: url('../../assets/amap/dibiao.png');
  background-size: 44px 52px;
}
.db-info {
  padding: 0 6px;
  height: 41px;
  background: rgba(48, 32, 10, 0.32);
  color: #fff;
  height: 22px;
  font-size: 12px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}
.amap-marker-label {
  border: none;
  background: none;
}
</style>
