<template>
  <div class="live-container">
    <div v-if="multiPage" class="changePage" @click="changePage">
      {{ firstPage ? '下一页' : '上一页' }}
    </div>
    <!-- PC端 -->
    <template v-if="!isMobile">
      <template v-if="!isOnePageMutil">
        <div
          class="video-container"
          v-for="channelNo in channelNoList"
          @click="onClickPC(channelNo)"
          :key="channelNo"
        >
          <!-- <div class="video-item" :id="`video-container${channelNo}`"></div> -->
          <img style="width: 90%; height: 100%" :src="getCoverImg(channelNo)" />
        </div>
      </template>
      <template v-else>
        <div
          class="video-container-onePage"
          v-for="camera in cameraInfoList"
          @click="onClickPC(camera.channelNoList[0], camera.deviceSerial)"
          :key="camera.deviceSerial"
        >
          <!-- <div class="video-item" :id="`video-container${channelNo}`"></div> -->
          <img
            style="width: 90%; height: 90%"
            :src="getCoverImg(camera.channelNoList[0], camera.deviceSerial)"
          />
          <div class="deviceSerial-name">{{ camera.name }}</div>
        </div>
      </template>
      <template v-if="extraData.length">
        <div
          class="video-container-onePage"
          v-for="(item, index) in extraData"
          @click="onClickPC('', '', item.url)"
          :key="index"
        >
          <!-- <div class="video-item" :id="`video-container${channelNo}`"></div> -->
          <img style="width: 90%; height: 90%" :src="getExtraCoverImg(index)" />
          <div class="deviceSerial-name">{{ item.name }}</div>
        </div>
      </template>
    </template>
    <!-- 移动端 -->
    <template v-else>
      <div
        class="video-cover"
        v-for="(channelNo, index) in channelNoList"
        @click="onClickMobile(index, channelNo)"
        :key="channelNo"
      >
        <img :src="getCoverImg(channelNo)" />
      </div>
    </template>
  </div>
</template>

<script>
import EZUIKit from 'ezuikit-js'
import { getOpenUrl } from '@/api/yingshiLive'

export default {
  data() {
    return {
      urlObj: {
        // url1: 'ezopen://open.ys7.com/AC4600851/1.live',
      },
      timer: null,
      playerList: []
    }
  },
  props: {
    isOnePageMutil: {
      type: Boolean,
      default: false
    },
    extraData: {
      type: Array,
      default: () => []
    },
    cameraInfoList: {
      type: Array,
      default: () => []
    },
    channelNoList: {
      type: Array,
      default: () => []
    },
    accessToken: {
      type: String,
      default: ''
    },
    deviceSerial: {
      type: String,
      default: 'AC4600851'
    },
    playerWidth: {
      type: Number,
      default: 700
    },
    playerHeight: {
      type: Number,
      default: 400
    },
    type: {
      type: String,
      default: 'daRuoYan'
    },
    multiPage: {
      type: Boolean,
      default: false
    },
    firstPage: {
      type: Boolean,
      default: true
    },
    nextPagePath: {
      type: String,
      default: ''
    }
  },
  computed: {
    isMobile() {
      return this.$device?.mobile
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.clearTotalPlayer()
  },
  methods: {
    getCoverImg(channelNo, deviceSerial = '') {
      if (deviceSerial === '') {
        deviceSerial = this.deviceSerial
      }
      return require(`../../../assets/live/${this.type}/${deviceSerial}/live${channelNo}.png`)
    },
    getExtraCoverImg(index) {
      return require(`../../../assets/live/${this.type}/extra/${index + 1}.png`)
    },
    clearTotalPlayer() {
      for (let playerItem of this.playerList) {
        playerItem.curPlayer.stop()
      }
    },
    changePage() {
      // this.clearTotalPlayer()
      this.$router.push(this.nextPagePath)
    },
    async onClickPC(channelNo, deviceSerial = '', url) {
      // const url = this.playerList[index].url
      if (deviceSerial === '') {
        deviceSerial = this.deviceSerial
      }
      // const curChannelNo = this.channelNoList[index]
      const { accessToken, type } = this
      let res
      if (!url) {
        res = await getOpenUrl({
          accessToken,
          deviceSerial,
          channelNo
        })
      }
      const data = res?.data
      this.$router.push({
        path: url ? '/extraPlayer' : '/fullScreenPlayer',
        query: {
          type,
          accessToken,
          deviceSerial,
          channelNo,
          url: url || data?.url
        }
      })
    },
    async onClickMobile(index, channelNo) {
      // const curChannelNo = this.channelNoList[index]
      const { accessToken, deviceSerial, type } = this
      const { data } = await getOpenUrl({
        accessToken,
        deviceSerial,
        channelNo
      })
      this.$router.push({
        path: '/mobilePlayer',
        query: {
          url: data?.url,
          accessToken,
          type,
          channelNo,
          deviceSerial
        }
      })
    },
    async initPlayer() {
      const { accessToken, channelNoList } = this
      this.playerList = []
      const { deviceSerial, playerWidth, playerHeight } = this
      for (let channelNo of channelNoList) {
        const { data } = await getOpenUrl({
          accessToken,
          deviceSerial,
          channelNo
        })
        const url = data?.url
        const curPlayer = new EZUIKit.EZUIKitPlayer({
          id: `video-container${channelNo}`, // 视频容器ID
          accessToken,
          url,
          width: playerWidth,
          height: playerHeight
        })
        this.playerList.push({ curPlayer, url })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.live-container {
  height: calc(100vh - 105px);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow-y: scroll;

  .video-container {
    width: 50%;
    height: 45%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 16px;
  }

  .video-container-onePage {
    width: 50%;
    height: 44vh;
    display: flex;
    // overflow: hidden;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 16px;
    .deviceSerial-name {
      margin: 10px 0;
      color: #fff;
    }
  }

  .changePage {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    background: #409eff;
    position: absolute;
    top: -16px;
    right: 20px;
    z-index: 999;
    border-radius: 5%;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .live-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .video-container {
      width: 100%;
      height: 25vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .changePage {
      width: 80px;
      height: 80px;
      color: #fff;
      position: absolute;
      top: 20px;
      right: 0;
      z-index: 999;
      cursor: pointer;
    }

    .video-cover {
      width: 100%;
      height: 25vh;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
