var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-container"},[(!_vm.onlyPlan)?_c('div',{staticClass:"block-group",style:({
      marginTop: `${_vm.item.type === 'plan' ? 10 : 30}px`,
      background: `${_vm.item.type === 'plan' ? '#4A8DE0' : '#4CACA7'}`
    })}):_c('div',{staticClass:"block-group",style:({
      marginTop: `17px`,
      background: '#4A8DE0'
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }