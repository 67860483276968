import { getAccessToken } from '@/api/yingshiLive'

const state = {
  token: '',
  appKey: '',
  appSecret: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_KEYINFO: (state, keyInfo) => {
    const { appKey, appSecret } = keyInfo
    state.appKey = appKey
    state.appSecret = appSecret
  }
}

const actions = {
  getAccessToken({ commit }, { appKey, appSecret }) {
    return new Promise((resolve, reject) => {
      getAccessToken({ appKey, appSecret })
        .then((res) => {
          const { data } = res
          commit('SET_TOKEN', data?.accessToken)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
