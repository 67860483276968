<template>
  <div>
    <!-- <div class="start-btn btn2">zoom: {{ zoom }} pitch: {{ pitch }}</div>
    <div class="start-btn btn3"><a @click="getPitch">获取当前pitch</a></div> -->
    <!-- <div class="start-btn btn3"><a @click="onPause">暂停</a></div> -->
    <!-- <div class="start-btn btn3">
      <a @click="getMapRotation([0, 0], [1, 1])">暂停</a>
    </div> -->
    <div id="map">
      <div
        :class="`operation-container ${
          styleType === 'light' ? 'operation-light' : 'operation-dark'
        }`"
      >
        <div
          class="operation-icon"
          @mouseover="isStatusMenuShow = true"
          @mouseleave="isStatusMenuShow = false"
        >
          <img
            v-if="styleType === 'light'"
            src="../../assets/amap/changeMapStatus.png"
            alt=""
          />
          <img v-else src="../../assets/amap/changeMapStatusDark.png" alt="" />
          <ul
            @mouseover="isStatusMenuShow = true"
            @mouseleave="isStatusMenuShow = false"
            class="menu-group"
            v-show="isStatusMenuShow"
          >
            <li
              class="menu-item"
              @click="setMapStatus(statusItem.type)"
              v-for="statusItem in statusList"
              :key="statusItem.type"
            >
              {{ statusItem.title }}
            </li>
          </ul>
        </div>
        <div
          class="operation-icon"
          @mouseover="isDbMenuShow = true"
          @mouseleave="isDbMenuShow = false"
        >
          <img
            v-if="styleType === 'light'"
            src="../../assets/amap/queryDb.png"
            alt=""
          />
          <img v-else src="../../assets/amap/queryDbDark.png" alt="" />
          <ul
            @mouseover="isDbMenuShow = true"
            @mouseleave="isDbMenuShow = false"
            class="menu-group"
            v-show="isDbMenuShow"
          >
            <li
              class="menu-item"
              @click="queryBd(loca.pos)"
              v-for="loca in locationMarkList"
              :key="loca.title"
            >
              {{ loca.title }}
            </li>
          </ul>
        </div>
        <div
          class="operation-icon"
          @mouseover="isPlayMenuShow = true"
          @mouseleave="isPlayMenuShow = false"
        >
          <img
            v-if="styleType === 'light'"
            src="../../assets/amap/play.png"
            alt=""
          />
          <img v-else src="../../assets/amap/playDark.png" alt="" />
          <ul
            @mouseover="isPlayMenuShow = true"
            @mouseleave="isPlayMenuShow = false"
            class="menu-group"
            v-show="isPlayMenuShow"
          >
            <li class="menu-item" v-show="!isPlaying" @click="onStart">
              播放动画
            </li>
            <li
              class="menu-item"
              v-show="isPlaying && isPause"
              @click="onResume"
            >
              继续播放
            </li>
            <li
              class="menu-item"
              v-show="isPlaying && !isPause"
              @click="onPause"
            >
              暂停
            </li>
            <li class="menu-item" v-show="isPlaying" @click="onCancel">停止</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { roadPath, locationMarkList } from './_data/constants'

export default {
  data() {
    return {
      isPause: false,
      isPlaying: false,
      locationMarkList,
      isDbMenuShow: false,
      isStatusMenuShow: false,
      isPlayMenuShow: false,
      finished: true,
      polyline: {}, // 线路
      loca: {}, // 控制3d对象
      boatMarker: {}, // 标签图标
      map: {}, // 地图
      mapStatus: '2D',
      satelliteLayer: {}, // 卫星图层
      statusList: [
        { type: '2D', title: '平面图' },
        { type: '2.5D', title: '2.5D图' },
        { type: 'satellite', title: '卫星图' }
      ],
      // centerPos: [118.723712, 28.941258],
      centerPos: [118.68629, 28.936901],
      defaultZoom: 11.5,
      zoom: 0,
      center: [0, 0],
      pitch: 0
    }
  },
  props: {
    styleType: String,
    mapkey: String,
    securityJsCode: String,
    mapStyle: String
  },
  computed: {
    riverPath() {
      return roadPath
        .filter((_, index) => index % 2 !== 0)
        .filter((_, index) => index % 2 !== 0)
    }
  },
  methods: {
    getPitch() {
      this.pitch = this.map.getPitch()
    },
    initMap() {
      window._AMapSecurityConfig = {
        // serviceHost: '您的代理服务器域名或地址/_AMapService',
        securityJsCode: this.securityJsCode
      }

      AMapLoader.load({
        key: this.mapkey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.DistrictSearch', 'AMap.ControlBar'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        Loca: {
          //是否加载 Loca,缺省不加载
          version: '2.0.0' //Loca 版本,缺省1.3.2
        }
      })
        .then((AMap) => {
          const controlBar = new AMap.ControlBar({
            position: { top: '10px', right: '10px' }
          })
          this.satelliteLayer = new AMap.TileLayer.Satellite()
          this.map = new AMap.Map('map', {
            terrain: true, // 是否展示地形
            viewMode: '3D',
            zoom: this.defaultZoom, // 缩放级别
            zooms: [10, 18], // 缩放范围
            center: this.centerPos,
            mapStyle: this.mapStyle,
            showBuildingBlock: true
          })
          this.map.setLayers([this.satelliteLayer])
          this.map.addControl(controlBar) // 添加地图控制器
          this.map.on('zoomchange', () => {
            this.zoom = this.map.getZoom()
          })
          this.loca = new Loca.Container({
            map: this.map
          })
          // 生成地标箭头
          for (let loca of locationMarkList) {
            this.setMarker(AMap, '<div class="chanzha-marker"></div>', loca)
          }
          // 生成小船
          this.boatMarker = this.setMarker(
            AMap,
            '<div class="db-marker"></div>',
            {
              pos: this.riverPath[0]
            }
          )
          // 生成主河流
          this.polyline = this.setPolyline(AMap, this.riverPath)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    run() {
      if (!this.isPlaying) return
      if (!this.finished) {
        // 获取地图的中心点（随着相机运动，每一帧的中心点数据都会更新）
        var center = this.map.getCenter().toArray()
        this.boatMarker.setPosition(center) // 设置图标的位置到中心点
      }
      requestAnimationFrame(this.run)
    },
    getMapRotation(newPos, oldPos) {
      const x2 = newPos[0]
      const y2 = newPos[1]
      const x1 = oldPos[0]
      const y1 = oldPos[1]
      const angle =
        (Math.atan(Math.abs(y2 - y1) / Math.abs(x2 - x1)) * 180) / Math.PI
      if (x1 - x2 === 0) {
        if (y1 > y2) return 0
        return 180
      }
      if (y1 - y2 === 0) {
        if (x1 > x2) return -90
        return 90
      }
      const quadrant1 = x2 > x1 && y2 > y1
      const quadrant2 = x2 > x1 && y2 < y1
      const quadrant3 = x2 < x1 && y2 < y1
      const quadrant4 = x2 < x1 && y2 > y1
      let mapRotation
      if (quadrant1) mapRotation = -(90 - angle)
      if (quadrant2) mapRotation = -(90 + angle)
      if (quadrant3) mapRotation = 90 + angle
      if (quadrant4) mapRotation = 90 - angle
      console.log(angle, mapRotation)
      return mapRotation
    },
    setMarker(AMap, content, { pos, title }) {
      const marker = new AMap.Marker({
        position: pos,
        content,
        anchor: 'bottom-center',
        map: this.map
      })
      if (title) {
        marker.setLabel({
          direction: 'up',
          offset: new AMap.Pixel(10, 0), //设置文本标注偏移量
          content: `<div class='db-info'>${title}</div>` //设置文本标注内容
        })
        // 给marker添加点击事件
        marker.on('click', () => {
          window.parent.postMessage(
            { markerTitle: title },
            'https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6MjQxNTkxNH0='
          )
        })
      }
      return marker
    },
    setPolyline(AMap, path) {
      return new AMap.Polyline({
        path,
        isOutline: false,
        strokeColor: '#009AF8',
        strokeOpacity: 0.6,
        strokeWeight: 8,
        strokeStyle: 'solid',
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 500,
        map: this.map
      })
    },
    // 设置地图状态
    setMapStatus(status) {
      this.map.setRotation(0)
      this.isStatusMenuShow = false
      this.map.setZoomAndCenter(this.defaultZoom, this.centerPos)
      switch (status) {
        case '2D':
          this.map.remove([this.satelliteLayer])
          this.map.setPitch(0, false, 500)
          break
        case '2.5D':
          this.map.setPitch(55, false, 500)
          break
        default:
          this.map.setLayers([this.satelliteLayer])
          break
      }
    },
    queryBd(pos) {
      this.isDbMenuShow = false
      this.map.setZoomAndCenter(15, pos)
    },
    setPlayMapStatus() {
      this.map.setZoom(17)
      this.map.setPitch(64)
    },
    onStart() {
      this.setPlayMapStatus()
      this.boatMarker.setMap(this.map)
      this.loca.animate.start()
      this.isPlaying = true
      this.run()
      this.finished = false
      this.loca.viewControl.addTrackAnimate(
        {
          path: this.riverPath, // 镜头轨迹，二维数组，支持海拔
          duration: 200000, // 时长
          timing: [
            [0, 0.3],
            [1, 0.7]
          ], // 速率控制器
          rotationSpeed: 1 // 每秒旋转多少度
        },
        function () {
          this.finished = true
          console.log('完成')
        }
      )
    },
    onResume() {
      this.setPlayMapStatus()
      this.isPause = false
      this.finished = false
      this.loca.viewControl.resumeAnimate()
    },
    onPause() {
      this.finished = true
      this.isPause = true
      this.loca.viewControl.pauseAnimate()
    },
    onCancel() {
      this.isPlaying = false
      this.finished = true
      this.loca.viewControl.clearAnimates()
      this.boatMarker.setMap(null)
      this.setMapStatus('2D')
    }
  },
  watch: {
    mapStatus(newV) {
      this.setMapStatus(newV)
    }
  },
  mounted() {
    this.initMap()
  },
  beforeDestroy() {
    this.map.destroy()
  }
}
</script>

<style lang="less" scoped>
#map {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  .operation-light {
    border: 1px solid;
    border-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0)
      )
      1 1;
    background: rgba(255, 255, 255, 0.11) rgba(255, 255, 255, 0.12);
  }
  .operation-dark {
    background: url(../../assets/amap/operation-bg-dark.png);
    background-size: cover;
  }
  .operation-container {
    width: 320px;
    height: 66px;
    padding: 0 64px;
    box-sizing: border-box;
    backdrop-filter: blur(30px);
    position: absolute;
    left: 50%;
    margin-left: -160px;
    bottom: 50px;
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .operation-icon {
      width: 58px;
      height: 64px;
      line-height: 66px;
      padding: 12px 12px;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      img {
        width: 30px;
        height: 30px;
      }
      .menu-group {
        position: absolute;
        width: 150px;
        background: #eceff3;
        bottom: 65px;
        left: -50px;
        z-index: 999;
        li {
          width: 100%;
          height: 40px;
          text-align: center;
          background: #eceff3;
          font-size: 16px;
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          font-weight: 400;
          color: #343434;
          line-height: 40px;
          &:hover {
            background: #008e8e;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.demo-title {
  position: absolute;
  top: 50px;
  left: 200px;
  z-index: 1;
}

h1 {
  margin: 0;
  color: rgba(255, 255, 255, 0.75);
}

h3 {
  font-weight: normal;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.75);
}

.start-btn {
  position: absolute;
  /* bottom: 20px; */
  right: 20px;
  padding: 0 18px;
  height: 30px;
  background-color: #1a66ff;
  border-radius: 5px;
  z-index: 1;
  cursor: pointer;
}
.btn1 {
  bottom: 140px;
}
.btn2 {
  bottom: 80px;
}
.btn3 {
  bottom: 20px;
}

.start-btn > a {
  color: #fff;
  display: block;
  height: 100%;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
}
</style>

<style>
.db-marker {
  width: 40px;
  height: 50px;
  background: url('../../assets/amap/dibiao.png');
  background-size: 44px 52px;
}
.chanzha-marker {
  width: 60px;
  height: 70px;
  background: url('../../assets/amap/chuanzha.png');
  background-size: cover;
}
.boat-marker {
  width: 50px;
  height: 50px;
  background: url('../../assets/amap/boat.png');
  background-size: 44px 52px;
}
.db-info {
  padding: 0 6px;
  height: 41px;
  background: rgba(48, 32, 10, 0.32);
  color: #fff;
  height: 22px;
  font-size: 12px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}
.amap-marker-label {
  border: none;
  background: none;
}
</style>
