<template>
  <div class="container">
    <div class="mobilePlayer-container">
      <div class="left-part" v-if="isToolShow">
        <div class="back" @click="$router.go(-1)">< 返回</div>
        <template
          v-if="
            ['wenZhouBei', 'dongYang', 'siGaiSan'].includes(type) ||
            (type === 'daRuoYan' && channelNo == 1)
          "
        >
          <div class="btn-group">
            <template v-for="direction in [0, 1, 2, 3, 8, 9]">
              <img
                :key="direction"
                :class="`d d${direction}`"
                :src="getDirectionImg(direction)"
                @click.stop="onPtzStart(channelNo, direction)"
                alt=""
              />
            </template>
          </div>
        </template>
      </div>
      <div class="right-part"><div id="mobile-player"></div></div>
    </div>
  </div>
</template>

<script>
import EZUIKit from 'ezuikit-js'
import { ptzStart, ptzStop } from '@/api/yingshiLive'
import { debounce } from '@/utils'

export default {
  data() {
    return {
      timer: null,
      isToolShow: false,
      curPlayer: null,
      type: '',
      accessToken: '',
      channelNo: null,
      deviceSerial: ''
    }
  },
  methods: {
    getDirectionImg(direction) {
      return require(`../../../assets/live/d${direction}.png`)
    },
    orientationChange() {
      if (window.orientation == 90 || window.orientation == -90) {
        console.log('横屏')
        window.location.reload()
      }
    },
    onPtzStart: debounce(
      async function (channelNo, direction, speed = 1) {
        try {
          const { accessToken, deviceSerial } = this
          await ptzStart({
            accessToken,
            deviceSerial,
            channelNo,
            direction,
            speed
          })
          const timer = setTimeout(() => {
            clearTimeout(timer)
            this.onPtzStop(channelNo, accessToken, deviceSerial)
          }, 500)
        } catch (e) {
          throw new Error(e)
        }
      },
      200,
      false
    ),
    async onPtzStop(channelNo, accessToken, deviceSerial) {
      try {
        await ptzStop({
          accessToken,
          deviceSerial,
          channelNo
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    async initPlayer(url, accessToken) {
      this.curPlayer = new EZUIKit.EZUIKitPlayer({
        id: `mobile-player`, // 视频容器ID
        accessToken,
        url
      })
    },
    startTimeout() {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.curPlayer.stop()
        this.$alert('您已长时间观看监控，请重新获取视频源', '提示', {
          confirmButtonText: '确定'
        }).then(() => {
          window.location.reload()
        })
      }, 600000)
    }
  },
  mounted() {
    const { url, accessToken, type, channelNo, deviceSerial } =
      this.$route.query
    this.type = type
    this.accessToken = accessToken
    this.deviceSerial = deviceSerial
    this.channelNo = channelNo
    this.initPlayer(url, accessToken)
    const pageTimer = setTimeout(() => {
      clearTimeout(pageTimer)
      this.isToolShow = true
    }, 1000)
    // window.addEventListener('orientationchange', this.orientationChange)
    this.startTimeout()
    document.addEventListener('mousemove', this.startTimeout)
    document.addEventListener('keydown', this.startTimeout)
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.curPlayer.stop()
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  transform: rotate(90deg);
  transform-origin: 50vw 50vw;
  .mobilePlayer-container {
    width: 100%;
    height: 100%;
    // position: relative;
    display: flex;
    .left-part {
      width: 50px;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      padding: 5px;
      .back {
        height: 20px;
        color: #fff;
        cursor: pointer;
        margin-bottom: 20px;
      }
      .btn-group {
        width: 50px;
        height: 100%;
        text-align: center;
        .d {
          width: 36px;
          height: 36px;
          margin-bottom: 10px;
          cursor: pointer;
        }
        .d0 {
          left: 80px;
          top: 180px;
        }
        .d1 {
          left: 80px;
          top: 100px;
        }
        .d2 {
          left: 125px;
          top: 140px;
        }
        .d3 {
          left: 36px;
          top: 140px;
        }
        .d8 {
          left: 125px;
          top: 40px;
        }
        .d9 {
          left: 36px;
          top: 40px;
        }
      }
    }
    .right-part {
      width: 100%;
      height: 100%;
      #mobile-player {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (orientation: landscape) {
  .container {
    width: 100vw;
    height: 100vh;
    transform: rotate(0deg);
    .mobilePlayer-container {
      width: 100%;
      height: 100%;
      // position: relative;
      display: flex;
      .left-part {
        width: 50px;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        padding: 5px;
        .back {
          height: 20px;
          color: #fff;
          cursor: pointer;
          margin-bottom: 20px;
        }
        .btn-group {
          width: 50px;
          height: 100%;
          text-align: center;
          .d {
            width: 36px;
            height: 36px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          .d0 {
            left: 80px;
            top: 180px;
          }
          .d1 {
            left: 80px;
            top: 100px;
          }
          .d2 {
            left: 125px;
            top: 140px;
          }
          .d3 {
            left: 36px;
            top: 140px;
          }
          .d8 {
            left: 125px;
            top: 40px;
          }
          .d9 {
            left: 36px;
            top: 40px;
          }
        }
      }
      .right-part {
        width: 100%;
        height: 100%;
        #mobile-player {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

// .mobilePlayer-container {
//   width: 100vw;
//   height: 100vh;
//   transform: rotate(90deg);
//   transform-origin: 50vw 50vw;
//   position: relative;

//   #mobile-player {
//     width: 100%;
//     height: 100%;
//     z-index: 0;
//     .mobile-playerdraw-window {
//       z-index: 0 !important;
//     }
//   }
//   .back {
//     height: 20px;
//     color: #fff;
//     position: absolute;
//     top: 10px;
//     left: 10px;
//     z-index: 9999;
//     cursor: pointer;
//   }
//   .btn-group {
//     .d {
//       z-index: 9999;
//       width: 48px;
//       height: 48px;
//       position: absolute;
//       cursor: pointer;
//     }
//     .d0 {
//       left: 80px;
//       top: 180px;
//     }
//     .d1 {
//       left: 80px;
//       top: 100px;
//     }
//     .d2 {
//       left: 125px;
//       top: 140px;
//     }
//     .d3 {
//       left: 36px;
//       top: 140px;
//     }
//     .d8 {
//       left: 125px;
//       top: 40px;
//     }
//     .d9 {
//       left: 36px;
//       top: 40px;
//     }
//   }
// }

// @media screen and (orientation: landscape) {
//   // /*竖屏样式*/
//   .mobilePlayer-container {
//     width: 100vw;
//     height: 100vh;
//     transform: rotate(0deg);
//     position: relative;

//     #mobile-player {
//       width: 100%;
//       height: 100%;
//       z-index: 0;
//       .mobile-playerdraw-window {
//         z-index: 0 !important;
//       }
//     }
//     .back {
//       width: 80px;
//       height: 80px;
//       color: #fff;
//       position: absolute;
//       top: 10px;
//       left: 10px;
//       z-index: 9999;
//       cursor: pointer;
//     }
//     .btn-group {
//       .d {
//         z-index: 9999;
//         width: 48px;
//         height: 48px;
//         position: absolute;
//         cursor: pointer;
//       }
//       .d0 {
//         left: 80px;
//         top: 180px;
//       }
//       .d1 {
//         left: 80px;
//         top: 100px;
//       }
//       .d2 {
//         left: 125px;
//         top: 140px;
//       }
//       .d3 {
//         left: 36px;
//         top: 140px;
//       }
//       .d8 {
//         left: 125px;
//         top: 40px;
//       }
//       .d9 {
//         left: 36px;
//         top: 40px;
//       }
//     }
//   }
// }
</style>
