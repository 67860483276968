export const locationMarkList = [
  {
    pos: [120.283289, 29.254699],
    type: 'marker',
    name: '本项目起点',
    info: {
      content: '本项目起点<br/>K32+007',
      anchor: 'bottom-center',
      color: 'green'
    }
  },
  {
    pos: [120.280141, 29.265603],
    type: 'marker',
    name: '金义东轻轨体育场站',
    info: {
      content: '金义东轻轨体育场站',
      anchor: 'bottom-center',
      color: 'red',
      images: [
        {
          class: 'img-sign',
          name: 'dy5.jpg'
        }
      ]
    }
  },
  {
    pos: [120.278018, 29.273178],
    type: 'marker',
    name: '迎宾大道隧道',
    info: {
      content: '迎宾大道隧道',
      anchor: 'bottom-center',
      color: 'red',
      images: [
        {
          class: 'img-sign',
          name: 'dy1.jpg'
        }
      ]
    }
  },
  {
    pos: [120.276054, 29.279711],
    type: 'marker',
    name: '迎宾大桥',
    info: {
      content: '迎宾大桥',
      anchor: 'bottom-center',
      color: 'red',
      images: [
        {
          class: 'img-sign',
          name: 'dy2.jpg'
        }
      ]
    }
  },
  {
    pos: [120.272871, 29.28879],
    type: 'marker',
    name: '广福东街特大桥',
    info: {
      content: '广福东街特大桥',
      anchor: 'bottom-center',
      color: 'red',
      images: [
        {
          class: 'img-sign',
          name: 'dy3.jpg'
        }
      ]
    }
  },
  {
    pos: [120.26487, 29.300834],
    type: 'marker',
    name: '湖莲西街改造起点',
    info: {
      content: '湖莲西街改造起点<br/>HKO+183',
      anchor: 'bottom-center',
      color: 'red'
    }
  },
  {
    pos: [120.271458, 29.302116],
    type: 'marker',
    name: '湖莲西街改造终点',
    info: {
      content: '湖莲西街改造终点<br/>HKO+884',
      anchor: 'bottom-center',
      color: 'red',
      images: [
        {
          class: 'img-sign',
          name: 'dy4.jpg'
        }
      ]
    }
  },
  {
    pos: [120.267671, 29.302312],
    type: 'marker',
    name: '本项目终点',
    info: {
      content: '本项目终点<br/>K37+000',
      anchor: 'bottom-center',
      color: 'green'
    }
  }
  // { pos: [120.277495, 29.274806], type: 'bridge', title: '一工区视频监控' },
  // { pos: [120.273067, 29.288116], type: 'bridge', title: '二工区视频监控' }
]

export const directionMarkList = [
  {
    pos: [120.285247, 29.257644],
    type: 'image',
    title: '横店方向',
    titleConfig: {
      class: 'arrow-info',
      direction: 'bottom'
    },
    anchor: 'middle-left',
    images: [
      {
        class: 'dy-arrow-start',
        name: 'redArrow.png'
      }
    ]
  },
  {
    pos: [120.266903, 29.294549],
    type: 'image',
    title: '甬金高速方向',
    titleConfig: {
      class: 'arrow-info',
      direction: 'top'
    },
    anchor: 'middle-left',
    images: [
      {
        class: 'dy-arrow-end',
        name: 'redArrow.png'
      }
    ]
  },
  {
    pos: [120.277495, 29.274806],
    type: 'image',
    title: '一工区视频监控',
    titleConfig: {
      class: 'jkTitle',
      direction: 'top'
    },
    anchor: 'middle-left',
    images: [
      {
        class: 'jkMaker',
        name: 'jk.png'
      }
    ]
  },
  {
    pos: [120.273067, 29.288116],
    type: 'image',
    title: '二工区视频监控',
    titleConfig: {
      class: 'jkTitle',
      direction: 'top'
    },
    anchor: 'middle-left',
    images: [
      {
        class: 'jkMaker',
        name: 'jk.png'
      }
    ]
  }
]

export const markerPathList = [
  //迎宾大道隧道
  {
    path: [
      [120.278494, 29.271284],
      [120.278196, 29.272388],
      [120.278035, 29.272917],
      [120.277885, 29.273446]
    ],
    strokeColor: '#f1a8ac',
    strokeWeight: 8,
    zIndex: 501,
    lineCap: 'kCGLineCapRound'
  },
  //隧道红色区域
  {
    path: [
      [120.278196, 29.272388],
      [120.278035, 29.272917]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 501,
    lineCap: 'kCGLineCapRound'
  },
  //迎宾大桥
  {
    path: [
      [120.277068, 29.276305],
      [120.274938, 29.283158]
    ],
    strokeColor: 'yellow',
    strokeWeight: 8,
    zIndex: 501,
    lineCap: 'kCGLineCapRound'
  },
  //广福东街特大桥
  {
    path: [
      [120.274322, 29.284789],
      [120.271075, 29.293342]
    ],
    strokeColor: '#ce97c1',
    strokeWeight: 8,
    zIndex: 501,
    lineCap: 'kCGLineCapRound'
  },
  //湖莲西街隧道
  {
    path: [
      [120.26487, 29.300834],
      [120.268921, 29.301592],
      [120.267311, 29.301292],
      [120.271458, 29.302116]
    ],
    strokeColor: '#23baec',
    strokeWeight: 8,
    zIndex: 501,
    lineCap: 'kCGLineCapRound'
  },
  //湖莲西街隧道红色标记段
  {
    path: [
      [120.268921, 29.301592],
      [120.267311, 29.301292]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 501,
    lineCap: 'kCGLineCapRound'
  }
]

export const roadPath = [
  [120.283289, 29.254699],
  [120.278494, 29.271284],
  [120.278196, 29.272388],
  [120.278035, 29.272917],
  [120.277885, 29.273446],
  [120.277068, 29.276305],
  [120.274938, 29.283158],
  [120.274322, 29.284789],
  [120.271075, 29.293342],
  [120.267671, 29.302312]
]

export default {
  locationMarkList,
  roadPath,
  markerPathList,
  directionMarkList
}
