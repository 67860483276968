<template>
  <ul>
    <li v-for="item in columns" :key="item.dataIndex">{{ item.name }}</li>
  </ul>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    columns() {
      return window.$wujie?.props.ganttColumns
    }
  }
}
</script>

<style lang="less" scoped>
ul {
  width: 760px;
  padding-left: 40px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 80px;
  background: #ffffff;
  padding-left: 40px;
  li {
    width: 120px;
    height: 80px;
    text-align: center;
    font-size: 18px;
    font-family: Helvetica;
    color: #686b73;
    line-height: 80px;
    border-right: 1px solid #e2e4e8;
  }
}
</style>
