export const locationMarkList = [
  {
    pos: [120.715712, 30.710338],
    type: 'image',
    anchor: 'top-center',
    name: '项目部',
    images: [
      {
        class: 'gang-marker',
        name: 'star.svg'
      }
    ],
    info: {
      content: '项目部',
      anchor: 'bottom-center',
      color: 'blue',
      images: [
        {
          class: 'img-sign',
          name: 'xingmubu.png'
        }
      ]
    }
  },
  {
    pos: [120.705789, 30.739625],
    type: 'marker',
    name: '主线高架桥起点',
    info: {
      content: '项目(主线高架桥)起点<br/>-K0+085',
      anchor: 'top-center',
      color: 'red'
    }
  },
  {
    pos: [120.705442, 30.739608],
    type: 'marker',
    name: '三环西路节点钢-混组合梁',
    info: {
      content: '三环西路节点钢-混组合梁<br/>(49+70+52)-K0+0853K0+086',
      anchor: 'bottom-center',
      color: 'red'
    }
  },
  {
    pos: [120.694195, 30.735382],
    type: 'marker',
    name: '上跨南郊河(N级航道)天佑大桥连续 钢-UHPC组合梁',
    info: {
      content:
        '上跨南郊河(N级航道)<br/>天佑大桥连续 钢-UHPC组合梁<br/>(60+84+60)K1+053至K1+197',
      anchor: 'bottom-center',
      color: 'red'
    }
  },
  {
    pos: [120.693765, 30.734966],
    type: 'marker',
    name: '上跨(常台高速)钢混组合梁',
    info: {
      content: '上跨(常台高速)钢混组合梁<br/>(1×65) K1+197至K1+262',
      anchor: 'top-center',
      color: 'red'
    }
  },
  {
    pos: [120.668032, 30.721013],
    type: 'marker',
    name: '桐乡射线主线高架桥',
    info: {
      content:
        '桐乡射线主线高架桥6883m<br/>' +
        '(49+70+52)+29+30+6x32+(2x50)+29+30+32+3×35+<br/>' +
        '(50)+2x35+2×32+2x30+4x29+(60+84+60)+(65)+2<br/>' +
        '9+6x30+(45)+2x30+2x35+2x29+7×30+29+3x35+(65)<br/>' +
        '+2×35+30+29+30+5×35+2x30+3x29+2x35+2x32+5×30+32<br/>' +
        '+2×35+(45)+18x30+(45)+4x30+3x32+8x35+(65)+<br/>' +
        '35+16×30+35+(65)+35+(45)+6x30+3x29+3×30+4×35<br/>' +
        '+(50)+2x35+5×30+32+2x30+35+(45)+5×32+10×30+3<br/>' +
        '5+(65)+3x35+(65)+2x35+145)+3x30+29+30',
      anchor: 'bottom-center',
      color: 'red'
    }
  },
  {
    pos: [120.663147, 30.718803],
    type: 'marker',
    name: '地面辅道K4+686至K5+992',
    info: {
      content: '地面辅道<br/>K4+686至K5+992',
      anchor: 'bottom-center',
      color: 'purple'
    }
  },
  {
    pos: [120.657497, 30.71163],
    type: 'marker',
    name: '在建机场',
    info: {
      content: '在建机场',
      anchor: 'top-center',
      color: 'red'
    }
  },
  {
    pos: [120.650967, 30.71087],
    type: 'image',
    anchor: 'top-center',
    images: [
      {
        class: 'gang-marker',
        name: 'gang.svg'
      }
    ],
    name: '钢筋加工厂1',
    info: {
      content: '钢筋加工厂',
      anchor: 'bottom-center',
      color: 'blue',
      images: [
        {
          class: 'img-sign',
          name: 'steelHouse.png'
        }
      ]
    }
  },
  {
    pos: [120.646946, 30.710151],
    type: 'marker',
    name: '地面铺道K6+399至K7+724',
    info: {
      content: '地面铺道<br/>K6+399至K7+724',
      anchor: 'bottom-center',
      color: 'purple'
    }
  },
  {
    pos: [120.698972, 30.738178],
    type: 'image',
    anchor: 'bottom-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.690893, 30.734085],
    type: 'image',
    anchor: 'bottom-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.680466, 30.728305],
    type: 'image',
    anchor: 'bottom-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.67254, 30.724492],
    type: 'image',
    anchor: 'bottom-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.667805, 30.721136],
    type: 'image',
    anchor: 'bottom-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.658553, 30.715097],
    type: 'image',
    anchor: 'top-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.652277, 30.710738],
    type: 'image',
    anchor: 'top-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.651388, 30.709493],
    type: 'image',
    anchor: 'top-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.647948, 30.709741],
    type: 'image',
    anchor: 'top-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.643746, 30.708294],
    type: 'marker',
    name: '主线高架桥终点',
    info: {
      content: '主线高架桥<br/>终点K6+798',
      anchor: 'bottom-center',
      color: 'red'
    }
  },
  {
    pos: [120.642992, 30.707723],
    type: 'image',
    anchor: 'bottom-center',
    images: [
      {
        class: 'gang-marker',
        name: 'gang.svg'
      }
    ],
    name: '钢筋加工厂2',
    info: {
      content: '钢筋加工厂',
      anchor: 'bottom-center',
      color: 'blue',
      images: [
        {
          class: 'img-sign',
          name: 'steelHouse.png'
        }
      ]
    }
  },
  {
    pos: [120.637269, 30.705889],
    type: 'marker',
    name: '下穿通道段(六车道U型撸)',
    info: {
      content: '下穿通道段(六车道U型撸)<br/>K6+850至K7+465',
      anchor: 'bottom-center',
      color: 'red',
      images: [
        {
          class: 'img-sign',
          name: 'thoroughfare.png'
        }
      ]
    }
  },
  {
    pos: [120.640786, 30.706473],
    type: 'image',
    anchor: 'top-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.637859, 30.705667],
    type: 'image',
    anchor: 'top-center',
    images: [
      {
        class: 'bianyaqi-marker',
        name: 'bianyaqi.svg'
      }
    ]
  },
  {
    pos: [120.635034, 30.705473],
    type: 'marker',
    name: '项目终点',
    info: {
      content: '项目终点<br/>k7+724',
      anchor: 'top-center',
      color: 'red'
    }
  },
  //桩号
  {
    pos: [120.694831, 30.73504],
    type: 'image',
    anchor: 'top-center',
    isShowMenu: false,
    title: 'k1',
    titleConfig: {
      class: 'pile-info',
      direction: 'bottom'
    },
    images: [
      {
        class: 'zhuanghao-rotate-marker',
        name: 'zhuanghao.svg'
      }
    ]
  },
  {
    pos: [120.687072, 30.730946],
    type: 'image',
    anchor: 'top-center',
    isShowMenu: false,
    title: 'k2',
    titleConfig: {
      class: 'pile-info',
      direction: 'bottom'
    },
    images: [
      {
        class: 'zhuanghao-rotate-marker',
        name: 'zhuanghao.svg'
      }
    ]
  },
  {
    pos: [120.677873, 30.726521],
    type: 'image',
    anchor: 'bottom-center',
    isShowMenu: false,
    title: 'k3',
    titleConfig: {
      class: 'pile-info'
    },
    images: [
      {
        class: 'zhuanghao-marker',
        name: 'zhuanghao.svg'
      }
    ]
  },
  {
    pos: [120.67409, 30.724673],
    type: 'image',
    anchor: 'bottom-center',
    isShowMenu: false,
    title: 'k4',
    titleConfig: {
      class: 'pile-info'
    },
    images: [
      {
        class: 'zhuanghao-marker',
        name: 'zhuanghao.svg'
      }
    ]
  },
  {
    pos: [120.661184, 30.71688],
    type: 'image',
    anchor: 'top-center',
    isShowMenu: false,
    title: 'k5',
    titleConfig: {
      class: 'pile-info',
      direction: 'bottom'
    },
    images: [
      {
        class: 'zhuanghao-rotate-marker',
        name: 'zhuanghao.svg'
      }
    ]
  },
  {
    pos: [120.651274, 30.712653],
    type: 'image',
    anchor: 'bottom-center',
    isShowMenu: false,
    title: 'k6',
    titleConfig: {
      class: 'pile-info'
    },
    images: [
      {
        class: 'zhuanghao-marker',
        name: 'zhuanghao.svg'
      }
    ]
  },
  {
    pos: [120.641441, 30.707766],
    type: 'image',
    anchor: 'bottom-center',
    isShowMenu: false,
    title: 'k7',
    titleConfig: {
      class: 'pile-info'
    },
    images: [
      {
        class: 'zhuanghao-marker',
        name: 'zhuanghao.svg'
      }
    ]
  }
]

export const directionMarkList = [
  {
    pos: [120.705789, 30.739625],
    type: 'image',
    title: '嘉兴城区方向',
    titleConfig: {
      class: 'arrow-info',
      direction: 'top'
    },
    anchor: 'middle-left',
    images: [
      {
        class: 'arrow-start-marker',
        name: 'arrow.svg'
      }
    ]
  },
  {
    pos: [120.634982, 30.705407],
    type: 'image',
    title: '桐乡方向',
    titleConfig: {
      class: 'arrow-info',
      direction: 'top'
    },
    anchor: 'middle-right',
    images: [
      {
        class: 'arrow-end-marker',
        name: 'arrow.svg'
      }
    ]
  }
]

export const roadPath = [
  [120.705789, 30.739625], //钢混组合梁红色
  [120.704664, 30.739464],
  [120.702206, 30.7392], //钢混组合梁红色
  [120.701515, 30.739068],
  [120.699945, 30.73839],
  [120.699426, 30.738122], //钢混组合梁红色
  [120.698826, 30.7378],
  [120.695112, 30.735738],
  [120.694447, 30.735436], //钢混组合梁红色
  [120.693295, 30.73471],
  [120.690068, 30.733059], //钢混组合梁红色
  [120.689607, 30.732795],
  [120.686842, 30.731276], //钢混组合梁红色
  [120.68592, 30.730748],
  [120.678545, 30.726786], //钢混组合梁红色
  [120.677931, 30.726456],
  [120.673493, 30.724011], //钢混组合梁红色
  [120.672845, 30.723606],
  [120.668179, 30.721221], //钢混组合梁红色
  [120.667726, 30.720929],
  [120.663565, 30.718795], //地面铺道紫色
  [120.66282, 30.718342], //钢混组合梁红色
  [120.662386, 30.718118],
  [120.661872, 30.717808], //钢混组合梁红色
  [120.661658, 30.717685],
  [120.657352, 30.71542], //钢混组合梁红色
  [120.657015, 30.715242],
  [120.655676, 30.714567],
  [120.653819, 30.713552], //钢混组合梁红色
  [120.653417, 30.713353],
  [120.651464, 30.712343],
  [120.64819, 30.71059], //钢混组合梁红色
  [120.64794, 30.710447],
  [120.647361, 30.710143], //地面铺道紫色
  [120.646832, 30.709869], //钢混组合梁红色
  [120.646426, 30.709628],
  [120.643746, 30.708294], //钢铁加工厂蓝色
  [120.643422, 30.708062],
  [120.6432, 30.708015], //下穿通道段（六车道U型槽)紫红色
  [120.638526, 30.706287],
  [120.636448, 30.705652],
  [120.634982, 30.705407]
]

export const branchPathList = [
  [
    [120.657257, 30.715395],
    [120.657524, 30.714931],
    [120.657866, 30.714303],
    [120.658181, 30.713616],
    [120.658015, 30.712702],
    [120.657497, 30.71163]
  ]
]

export const markerPathList = [
  //钢铁加工厂蓝色
  {
    path: [
      [120.643746, 30.708294],
      [120.643422, 30.708062],
      [120.6432, 30.708015],
      [120.638526, 30.706287],
      [120.636448, 30.705652],
      [120.634982, 30.705407]
    ],
    strokeColor: '#5dc2d0',
    strokeWeight: 8,
    zIndex: 501,
    lineCap: 'kCGLineCapRound'
  },
  //下穿通道段（六车道U型槽)紫红色
  {
    path: [
      [120.6432, 30.708015],
      [120.638526, 30.706287],
      [120.636448, 30.705652]
    ],
    strokeColor: '#eb038b',
    strokeWeight: 8,
    zIndex: 502,
    lineCap: 'kCGLineCapRound'
  },
  //地面铺道紫色
  {
    path: [
      [120.647361, 30.710143],
      [120.646832, 30.709869],
      [120.646426, 30.709628],
      [120.643746, 30.708294],
      [120.643422, 30.708062],
      [120.6432, 30.708015],
      [120.638526, 30.706287],
      [120.636448, 30.705652],
      [120.634982, 30.705407]
    ],
    strokeColor: '#9b94c6',
    strokeWeight: 14,
    zIndex: 499,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.646832, 30.709869],
      [120.646426, 30.709628]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.64819, 30.71059],
      [120.64794, 30.710447]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //地面铺道紫色
  {
    path: [
      [120.663565, 30.718795],
      [120.66282, 30.718342],
      [120.662386, 30.718118],
      [120.661872, 30.717808],
      [120.661658, 30.717685],
      [120.657352, 30.71542],
      [120.657015, 30.715242],
      [120.655676, 30.714567],
      [120.653819, 30.713552],
      [120.653417, 30.713353],
      [120.651464, 30.712343]
    ],
    strokeColor: '#9b94c6',
    strokeWeight: 14,
    zIndex: 499,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.653819, 30.713552],
      [120.653417, 30.713353]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.657352, 30.71542],
      [120.657015, 30.715242]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.661872, 30.717808],
      [120.661658, 30.717685]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.66282, 30.718342],
      [120.662386, 30.718118]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.668179, 30.721221],
      [120.667726, 30.720929]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.673493, 30.724011],
      [120.672845, 30.723606]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.678545, 30.726786],
      [120.677931, 30.726456]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.686842, 30.731276],
      [120.68592, 30.730748]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.690068, 30.733059],
      [120.689607, 30.732795]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.694447, 30.735436],
      [120.693295, 30.73471]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.699426, 30.738122],
      [120.698826, 30.7378]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.702206, 30.7392],
      [120.701515, 30.739068]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  },
  //钢混组合梁红色
  {
    path: [
      [120.705789, 30.739625],
      [120.704664, 30.739464]
    ],
    strokeColor: 'red',
    strokeWeight: 8,
    zIndex: 503,
    lineCap: 'kCGLineCapRound'
  }
]

export default {
  locationMarkList,
  directionMarkList,
  roadPath,
  branchPathList,
  markerPathList
}
